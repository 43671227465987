import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, MenuItem, Select, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";

export default function MatchingWords() {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper
        style={{
          backgroundColor: "white",
          height: "400px",
          padding: "40px",
          width: "40%",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <div>
          <FormControl fullWidth>
            <InputLabel id="quiz-name-label">Quiz Name</InputLabel>
            <Select
              labelId="quiz-name-label"
              id="quiz-name"
              label="Quiz Name"
            >
              <MenuItem value={1}>Quiz 1</MenuItem>
              <MenuItem value={2}>Quiz 2</MenuItem>
              <MenuItem value={3}>Quiz 3</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: "20px" }}>
          <FormControl fullWidth>
            <InputLabel id="level-label">Level</InputLabel>
            <Select
              labelId="level-label"
              id="level"
              label="Level"
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div style={{ marginTop: "20px", marginLeft: "auto" }}>
          <Button variant="contained">Submit</Button>
        </div>
      </Paper>
    </div>
  );
}
