import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';

const CustomCheckBox = withStyles({
  root: {
    color: blue[300],
    '&$checked': {
      color: blue[900],
    },
    height: 24,
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

export default CustomCheckBox;
