import { Dialog, Link } from '@material-ui/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import { Button, Input, Label } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Transition } from '../../components/Common/EnhancedTableHead';

import { baseUrl, toHtmlEntities } from '../../helpers/EncryptionHelper';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import useEnterKeyListener from '../../helpers/useEnterKeyListener';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

const AddEnglishWordDialog = ({
  data,
  wordTypeArray,
  visible,
  handleClose,
  Transition,
  editEnglishWord,
}) => {
  const defaultWordRow = {
    wordType: null,
    definition: RichTextEditor.createEmptyValue(),
  };

  const [word, setWord] = useState('');
  const [wordType, setWordType] = useState(wordTypeArray);
  const [wordRow, setWordRow] = useState([defaultWordRow]);
  const [openAddWord, setOpenAddWord] = useState(false);
  const [modalOpenedFrom, setModalOpenedFrom] = useState(null);
  const [fetchedData, setFetchedData] = useState(null);

  const [isRTEBlurred, setIsRTEBlurred] = useState(true);

  function handleAddEnglishWord() {
    axios
      .post(baseUrl + '/english-word/add-word', {
        english_word_name: word,
        wordRow: wordRow.map((row) => ({
          ...row,
          definition: row.definition.toString('html'),
          wordType: row.wordType?.value,
        })),
      })
      .then((res) => {
        toastr.success('EnglishWord Added Successfully', 'Success');
        window.location.reload(false);
      })
      .catch((error) => {
        toastr.error('EnglishWord Add Failed', 'Error!');
      });
  }

  function handleEditEnglishWord() {
    let body = {};
    const stringifiedWordRow = wordRow.map((row) => ({
      ...row,
      definition: row.definition.toString('html'),
      wordType: row.wordType?.value,
    }));
    if (fetchedData?.word !== word) {
      body['english_word_name'] = word;
    }
    if (
      JSON.stringify(fetchedData?.wordRow) !==
      JSON.stringify(stringifiedWordRow)
    ) {
      body['wordRow'] = stringifiedWordRow;
    }
    axios
      .put(baseUrl + '/english-word/edit/' + data?.english_word_id, body)
      .then((res) => {
        toastr.success('EnglishWord Edit Successfully', 'Success');
        window.location.reload(false);
      })
      .catch((error) => {
        toastr.error('EnglishWord Edit Failed', 'Error!');
      });
  }

  const customControl = (index) => [
    () => (
      <button
        style={{
          border: '1px solid #999999',
          height: 30,
          textAlign: 'center',
          lineHeight: 0,
          borderRadius: 2,
          color: '#495057',
        }}
        type='button'
        onClick={() => {
          setModalOpenedFrom(index);
          setOpenAddWord(true);
        }}
      >
        Add Word
      </button>
    ),
  ];

  function handleEditorState(id, label) {
    const link = baseUrl + '/yoruba-word/get/' + id;
    const rteValue = `<span> </span><a href=${encodeURI(
      link
    )}>${label}</a><span>&nbsp;</span>`;
    let newEditorState;
    newEditorState = wordRow[modalOpenedFrom].definition
      .toString('html')
      .split('</p>')[0];
    newEditorState = newEditorState + rteValue + ' </p>';
    handleChangeState(
      'definition',
      wordRow[modalOpenedFrom].definition.setContentFromString(
        newEditorState,
        'html'
      ),
      modalOpenedFrom
    );
  }

  function handleChangeState(key, value, index) {
    setWordRow((wordRow) => {
      let newWordRow = [...wordRow];
      newWordRow[index][key] = value;
      return newWordRow;
    });
  }

  function handleValidation() {
    let errorMessage;

    if (!word) {
      errorMessage = 'Please enter English word';
    } else if (!wordRow[0]?.wordType) {
      errorMessage = 'Please select a Word Type';
    } else if (wordRow[0]?.definition.toString('html') === '<p><br></p>') {
      errorMessage = 'Please enter definition';
    }
    if (errorMessage) {
      toastr.error(errorMessage, 'Error!');
      return false;
    }
    return true;
  }

  useEffect(() => {
    function fetchEnglishWord() {
      axios
        .get(baseUrl + `/english-word/get/${data?.english_word_id}`)
        .then((res) => {
          if (res && res.data.status === 200) {
            setWord(res.data.data.english_word_name);
            let fetchedWordRow = res.data.data.wordRow;

            // Ensure wordRow is an array
            if (fetchedWordRow && !Array.isArray(fetchedWordRow)) {
              fetchedWordRow = [fetchedWordRow];
            }

            if (fetchedWordRow) {
              fetchedWordRow = fetchedWordRow?.map((row) => ({
                ...row,
                definition: defaultWordRow.definition.setContentFromString(
                  row.definition,
                  'html'
                ),
                wordType: wordTypeArray.find(
                  (word) => word.value === row.wordType
                ),
              }));
              setWordRow(fetchedWordRow);
              setFetchedData({
                word: res.data.data.english_word_name,
                wordRow: fetchedWordRow,
              });
            }
          }
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
    wordTypeArray?.length > 0 && fetchEnglishWord();
  }, [data, wordTypeArray]);

  useEnterKeyListener({
    querySelectorToExecuteClick: isRTEBlurred ? '#submitAddWordType' : null,
    deps: [isRTEBlurred],
  });

  useEffect(() => {
    if (wordTypeArray) {
      setWordType(wordTypeArray);
    }
    // fetchYorubaWords();
  }, [wordTypeArray]);

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth={'sm'}
      fullWidth
    >
      <div style={{ width: '100%', padding: 40 }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
        <div className='mb-3 mt-2'>
          <Label
            className='form-label required-field'
            htmlFor='formrow-name-input'
          >
            Name
          </Label>
          <Input
            required={true}
            type='text'
            className='form-control'
            id='wordNameInput'
            value={word}
            disabled={data && !editEnglishWord}
            onChange={(e) => setWord(e.target.value)}
            onFocus={() => (window.focusedInput = 'wordNameInput')}
          />
        </div>
        {wordRow &&
          wordRow.map((row, i) => (
            <div key={i}>
              {!(data && !editEnglishWord) && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <CloseIcon
                    style={{
                      top: 20,
                      right: 20,
                      cursor: 'pointer',
                      alignSelf: 'end',
                    }}
                    onClick={() => {
                      setWordRow((wordRow) => {
                        let row = wordRow;
                        if (wordRow.length > 1)
                          row = row.filter((item, index) => index !== i);
                        return row;
                      });
                    }}
                  />
                </div>
              )}
              <div className='mb-3'>
                <Label
                  className='form-label required-field'
                  htmlFor='formrow-word-input'
                >
                  Word Type
                </Label>
                <Autocomplete
                  id={`wordTypeSelectInput${i}`}
                  size='small'
                  options={wordType}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(_, newValue) => {
                    let newWordRow = [...wordRow];
                    newWordRow[i].wordType = newValue;
                    setWordRow(newWordRow);
                  }}
                  value={row.wordType}
                  disabled={data && !editEnglishWord}
                  onFocus={() =>
                    (window.focusedInput = `wordTypeSelectInput${i}`)
                  }
                />
              </div>
              <div
                className='mb-3'
                style={{
                  borderBottom: '1px solid gray',
                  paddingBottom: 40,
                  marginBottom: 20,
                }}
              >
                <Label
                  className='form-label required-field'
                  htmlFor='formrow-definition-input'
                >
                  Definition
                </Label>
                <RichTextEditor
                  editorClassName='editor'
                  value={row.definition}
                  onChange={(value) =>
                    handleChangeState('definition', value, i)
                  }
                  customControls={customControl(i)}
                  disabled={data && !editEnglishWord}
                  onBlur={(event) => setIsRTEBlurred(true)}
                  onFocus={(event) => setIsRTEBlurred(false)}
                />
              </div>
            </div>
          ))}
        {!(data && !editEnglishWord) && (
          <div className='actions clearfix'>
            <Link
              to='#'
              className='btn btn-secondary'
              onClick={() => {
                setWordRow((wordRow) => {
                  let row = [...wordRow];
                  row.push(defaultWordRow);
                  return row;
                });
              }}
              style={{
                alignItems: 'center',
                color: 'white',
              }}
            >
              + Add More
            </Link>
          </div>
        )}
        {openAddWord && (
          <AddWordModal
            setOpenAddWord={setOpenAddWord}
            handleEditorState={handleEditorState}
          />
        )}
        {!(data && !editEnglishWord) && (
          <div className='mt-4'>
            <Button
              id='submitAddWordType'
              color='primary'
              variant='contained'
              className='w-md'
              onClick={() => {
                if (handleValidation()) {
                  if (data) {
                    return handleEditEnglishWord();
                  }
                  handleAddEnglishWord();
                }
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

function AddWordModal({ setOpenAddWord, handleEditorState }) {
  const [selectedWordType, setSelectedWordType] = useState();
  const [searchType, setSearchType] = useState('contains');
  const [yorubaWordList, setYorubaWordList] = useState([]);
  function debounce(func, timeout = 1500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  const fetchYorubaWords = async (searchTerm, offset, limit) => {
    axios
      .get(baseUrl + `/yoruba-word/all?search=${searchTerm}&type=${searchType}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          const options = res.data.data.list.map((d) => ({
            value: d?.yoruba_word_id,
            label: d?.yoruba_word_name,
          }));
          setYorubaWordList(options);
        }
      })
      .catch((err) => {
        console.log('error');
      });
  };
  const processChange = debounce((searchTerm) => fetchYorubaWords(searchTerm));

  const handleSearchChange = (event) => {
    setSearchType(event.target.value);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option?.label,
    ignoreAccents: true,
    ignoreCase: true,
  });

  useEnterKeyListener({
    querySelectorToExecuteClick: '#submitAddWord',
  });

  return (
    <Dialog
      open={true}
      onClose={() => setOpenAddWord(false)}
      TransitionComponent={Transition}
    >
      <div style={{ width: 400, padding: 40 }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
          onClick={() => setOpenAddWord(false)}
        />
        <div className='mb-3'>
          <Label className='form-label' htmlFor='formrow-firstname-input'>
            Yoruba Word
          </Label>
          <InputLabel id="demo-simple-select-label">Search Type</InputLabel>
          <Select style={{ paddingBottom: '10px' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={searchType}
            label="Type"
            onChange={handleSearchChange}
          >
            <MenuItem value={'contains'}>Contains</MenuItem>
            <MenuItem value={'starts'}>Starts with</MenuItem>
            <MenuItem value={'match'}>Exact Matches with</MenuItem>
          </Select>
          <Autocomplete
            id='size-small-outlined-multi'
            size='small'
            options={yorubaWordList}
            getOptionLabel={(option) => option?.label || ''}
            renderInput={(params) => (
              <TextField placeholder='Choose a yoruba word' {...params} />
            )}
            onInputChange={(_, inputValue) => {
              if (inputValue.length > 0) {
                processChange(inputValue);
              }
            }}
            onChange={(_, newValue) => {
              setSelectedWordType(newValue);
            }}
            value={selectedWordType}
            filterOptions={filterOptions}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  key={option.value + option?.label}
                  style={{
                    color: 'black',
                    height: 40,
                    textDecorationColor: 'black',
                  }}
                >
                  {option.label}
                </li>
              );
            }}
          />
        </div>
        <div className='mt-4'>
          <Button
            id='submitAddWord'
            color='primary'
            variant='contained'
            className='w-md'
            onClick={() => {
              setOpenAddWord(false);
              handleEditorState(
                selectedWordType?.value,
                selectedWordType?.label
              );
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddEnglishWordDialog;
