import {
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import toastr from 'toastr';
import CloseIcon from '@material-ui/icons/Close';
import CustomCheckBox from '../../components/Common/CustomCheckBox';

import { baseUrl } from '../../helpers/EncryptionHelper';
import useEnterKeyListener from '../../helpers/useEnterKeyListener';
import StickyKeyboard from '../../components/Common/StickyKeyboard';

const defaultResponsibility = {
  all: false,
  users: false,
  wordType: false,
  englishWord: false,
  yorubaWord: false,
  dictionaryWord: false,
  dialectType: false,
  wordOfDay: false,
  subscriptionPlans:false,
  gameMaster:false,
  quiz:false
};

const AddUserDailog = ({
  data,
  visible,
  handleClose,
  Transition,
  editUser,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [assignedRes, setAssignedRes] = useState(defaultResponsibility);

  function handleValidation() {
    let errorText;
    if (!firstName) {
      errorText = 'Please enter first name';
    } else if (!lastName) {
      errorText = 'Please enter last name';
    } else if (!email) {
      errorText = 'Please enter email';
    } else if (!password) {
      errorText = 'Please enter password';
    } else if (!confirmPassword) {
      errorText = 'Please confirm your password';
    } else if (password !== confirmPassword) {
      errorText = 'Please enter correct password';
    }

    if (errorText) {
      toastr.error(errorText, 'Error!');
    } else {
      handleAddEditUser(editUser);
    }
  }

  function handleAddEditUser(isEdit) {
    let roles = Object.keys(assignedRes)
      .filter((key) => assignedRes[key] && key)
      .join(',');
    let endpoint = isEdit ? `/users/admin-edit/${data?.user_id}` : '/users/admin-create';
    let axiosMethod = isEdit ? axios.put : axios.post;
    axiosMethod(baseUrl + endpoint, {
      firstName,
      lastName,
      email: email,
      password: password,
      role: roles,
    })
      .then((res) => {
        toastr.success(
          `User ${isEdit ? 'Edited' : 'Added'} Successfully`,
          'Success'
        );
        window.location.reload(false);
      })
      .catch((error) => {
        toastr.error(`User ${isEdit ? 'Edit' : 'Add'} Failed`, 'Error!');
      });
  }

  const handleChange = (key, isChecked) => {
    console.log(key, isChecked);
    setAssignedRes({
      ...assignedRes,
      [key]: isChecked,
    });
  };

  const handleInputFocus = (inputId) => {
    window.focusedInput = inputId;
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: '#addUserSubmitButton',
  });

  useEffect(() => {
    if (data) {
      setFirstName(data.firstname);
      setLastName(data.lastname);
      setEmail(data.email);
      let roles = data.role.split(',');
      let fetchedRoles = { ...defaultResponsibility };
      Object.keys(defaultResponsibility).forEach((key) => {
        if (roles.includes(key)) {
          fetchedRoles[key] = true;
        }
      });
      setAssignedRes(fetchedRoles);
    }
  }, [data]);

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth='md'
      fullWidth
    >
      <div style={{ width: '100%', padding: 40 }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              flex: 2,
              borderRight: '1px solid #bbbaba',
              paddingRight: 20,
            }}
          >
            <Row>
              <Col lg='6'>
                <div className='mb-3'>
                  <Label for='basicpill-address-input1'>First Name</Label>
                  <Input
                    type='text'
                    className='form-control'
                    id='firstName'
                    value={firstName}
                    disabled={data && !editUser}
                    onChange={(e) => setFirstName(e.target.value)}
                    onFocus={() => handleInputFocus('firstName')}
                  />
                </div>
              </Col>
              <Col lg='6'>
                <div className='mb-3'>
                  <Label for='basicpill-address-input1'>Last Name</Label>
                  <Input
                    type='text'
                    className='form-control'
                    id='lastName'
                    value={lastName}
                    disabled={data && !editUser}
                    onChange={(e) => setLastName(e.target.value)}
                    onFocus={() => handleInputFocus('lastName')}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg='12'>
                <div className='mb-3'>
                  <Label for='basicpill-address-input1'>Email</Label>
                  <Input
                    type='email'
                    className='form-control'
                    id='email'
                    value={email}
                    disabled={data && !editUser}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => handleInputFocus('email')}
                  />
                </div>
              </Col>
            </Row>
            {!(data && !editUser) && (
              <Row>
                <Col lg='6'>
                  <div className='mb-3'>
                    <Label for='basicpill-address-input1'>Password</Label>
                    <Input
                      type='password'
                      className='form-control'
                      id='password'
                      value={password}
                      disabled={data && !editUser}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => handleInputFocus('password')}
                    />
                  </div>
                </Col>
                <Col lg='6'>
                  <div className='mb-3'>
                    <Label for='basicpill-address-input1'>
                      Confirm Password
                    </Label>
                    <Input
                      type='password'
                      className='form-control'
                      id='confirmPassword'
                      value={confirmPassword}
                      disabled={data && !editUser}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onFocus={() => handleInputFocus('confirmPassword')}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </div>

          <div
            style={{
              flex: 1,
              paddingLeft: 30,
              paddingTop: 0,

              marginLeft: 20,
            }}
          >
            <FormControl sx={{ m: 3 }} component='fieldset' variant='standard'>
              <Label for='basicpill-address-input1'>
                Assign responsibility
              </Label>
              <FormGroup>
                {Object.keys(assignedRes).map((key, i) => (
                  <FormControlLabel
                    control={
                      <CustomCheckBox
                        checked={assignedRes[key]}
                        onChange={(e) => handleChange(key, e.target.checked)}
                        name='gilad'
                        disabled={data && !editUser}
                      />
                    }
                    label={getText(key)}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
        {!(data && !editUser) && (
          <div className='mt-4'>
            <Button
              id='addUserSubmitButton'
              color='primary'
              variant='contained'
              className='w-md'
              onClick={() => {
                handleValidation();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default AddUserDailog;

function getText(key) {
  console.log(key);
  switch (key) {
    case 'all':
      return 'All';
    case 'users':
      return 'Users';
    case 'wordType':
      return 'Word Type';
    case 'englishWord':
      return 'English Words';
    case 'yorubaWord':
      return 'Yoruba Words';
    case 'dictionaryWord':
      return 'Dictionary Words';
    case 'dialectType':
      return 'Dialect Type';
    case 'wordOfDay':
      return 'Word Of The Day';
    case 'subscriptionPlans':
      return 'Subscription Plans';
    case 'gameMaster':
      return 'Game Master';
    case 'quiz':
      return 'Quiz';
    default:
      return 'All';
  }
}
