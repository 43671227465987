import React from 'react';
import { Col, Container, Row } from 'reactstrap';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomWordOfTheDay from './WordOfTheDayTable';
export default function WordOfTheDayList() {
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Dictionary'
            breadcrumbItem=' Word Of The Day'
          />
          <Row>
            <Col className='col-12'>
              <CustomWordOfTheDay />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
