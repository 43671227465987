import React from 'react';
import { Redirect } from 'react-router-dom';
// Dashboard
import Dashboard from '../pages/Dashboard/index';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
// Profile
import UserProfile from '../pages/Authentication/user-profile';
import WelcomePage from '../pages/WelcomePage/WelcomePage';
import MyProfile from '../pages/MyProfile/MyProfile';
import UserList from '../pages/Users/UserList';
import EnglishWordList from '../pages/EnglishWord/EnglishWordList';
import WordTypeList from '../pages/WordType/WordTypeList';
import YorubaWordList from '../pages/YorubaWord/YorubaWordList';
import AddYorubaWord from '../pages/DictionaryWords/AddDictionaryWord';
import DialectTypeList from '../pages/DialectType/DialectTypeList';
import DictionaryWordList from '../pages/DictionaryWords/DictionaryWordList';
import SubscriptionList from '../pages/Subscriptions/SubscriptionList';
import WordOfTheDayList from '../pages/WordOfTheDay/WordOfTheDayList';
import GameLevelList from '../pages/GameLevel/gameLevelList';
import GameMasterList from '../pages/GameMaster/gameMasterList';
import QuizList from '../pages/Quiz/quizList';
import MatchingWords from '../pages/MatchingWords/MatchingWords';
import QuizDialog from '../pages/Quiz/quizDialog';

const userRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/add-yoruba-word', component: AddYorubaWord },
  { path: '/welcome-page', component: WelcomePage },
  { path: '/my-profile', component: MyProfile },
  { path: '/user', component: UserList },
  { path: '/english-word', component: EnglishWordList },
  { path: '/yoruba-word', component: YorubaWordList },
  { path: '/dictionary-word', component: DictionaryWordList },
  { path: '/word-type', component: WordTypeList },
  { path: '/dialect-type', component: DialectTypeList },
  { path: '/subscription-Plans', component: SubscriptionList },
  { path: '/word-of-the-day', component: WordOfTheDayList },
  { path: '/game-Level', component: GameLevelList },
  { path: '/Game-Master', component: GameMasterList },
  { path: '/Quiz', component: QuizList },
  { path: '/Quiz-Form', component: QuizDialog },
  { path: '/Matching-Words', component: MatchingWords },


  // //profile
  { path: '/profile', component: UserProfile },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
];

export { userRoutes, authRoutes };
