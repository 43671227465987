import React from 'react';
import { Col, Container, Row } from 'reactstrap';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomGameMasterType from './gameMasterTable';
export default function GameMasterList() {
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Dictionary'
            breadcrumbItem='Game Master'
          />
          <Row>
            <Col className='col-12'>
              <CustomGameMasterType />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
