import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import CloseIcon from "@material-ui/icons/Close";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button, Input, Label } from "reactstrap";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import { baseUrl } from "../../helpers/EncryptionHelper";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
} from "@mui/material";

// FORMER DEFAULT VALUE
// const defaultValue = {
//   question: '',
//   options: {
//     A: '',
//     B: '',
//     C: '',
//     D: '',
//     E: ''
//   },
//   selectedOption: '',
//   pairs: {},
// };

const defaultValue = {
  quiz_id: null,
  question: "", // If LinguaTranslate: Yoruba Word or English Word | If Odd One Out, Yoruba Word as first hint
  question_id: null, // ID if the question is Yoruba Word (i.e., if Yoruba-English, or odd one out yoruba word)
  audio_url: "", // Audio URL if the question is Yoruba Word (i.e., if Yoruba-English)
  englishHint: "", // if Odd One Out game, English Word
  options: {
    A: {}, // if English-Yoruba, or Odd One Out: e.g., {word_id: 8129, word_name: 'nikan'}
    B: {}, // if Yoruba-English: e.g., {word_id: null, word_name: 'word'}
    C: {},
    D: {},
  },
  selectedOption: {}, // {word_id: 8129, word_name: 'nikan'} or {word_id: null, word_name: 'word'}
  pairs: {}, // if Twin Pairs game: e.g., {key0: {word_id: 8129, word_name: 'nikan'}, value0: {word_id: 100, word_name: 'aabo'}, key1: {word_id: 89, word_name: 'baba'}, value1: {word_id: 29, word_name: 'jagun'}, ...}
};

const QuizDialog = (props) => {
  const history = useHistory();
  const { gameData } = props.history?.location?.state || {};
  const [quizName, setQuizName] = useState("");
  const [level, setLevel] = useState("");
  const [gameNames, setGameNames] = useState([]);
  const [gameId, setGameId] = useState(null);
  const [rowData, setRowData] = useState([{ ...defaultValue }]);
  const [quizType, setQuizType] = useState("");
  const [remainingYorubaWordList, setRemainingYorubaWordList] = useState([]);

  const classes = useStyles();

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option?.label,
    ignoreAccents: true,
    ignoreCase: true,
  });

  function handleValidation() {
    let errorText;
    if (!quizName) {
      errorText = "Please select Quiz Name";
    } else if (!quizType) {
      errorText = "Please select Quiz Type";
    } else if (!level) {
      errorText = "Please enter quiz level";
    } else if (quizName === "Twin Pairs") {
      if (rowData?.find((data) => Object.keys(data?.pairs)?.length % 2 != 0)) {
        errorText = "Please select all matching pairs";
      }
    } else if (rowData?.find((data) => data.selectedOption == null || data.selectedOption == '' || (typeof data.selectedOption === 'object' && Object.keys(data.selectedOption).length <= 0))) {
      errorText = "Please select an answer";
    }
    if (errorText) {
      toastr.error(errorText, "Error!");
    } else {
      handleAddEditQuiz(props.editQuiz);
    }
  }

  function renderOptions(props, option) {
    return (
      <li
        {...props}
        key={option.value + option?.label}
        style={{
          color: "black",
          height: 40,
          textDecorationColor: "black",
        }}
      >
        {option.label}
      </li>
    );
  }

  function handleAddEditQuiz(isEdit) {
    let endpoint = isEdit
      ? `/quiz/edit/${props.data?.quiz_id}`
      : "/quiz/add-quiz";
    let axiosMethod = isEdit ? axios.put : axios.post;
    const reqBody = {};
    reqBody.data = rowData?.map((row) => {
      if (
        quizType === "English-Yoruba" ||
        quizType === "Yoruba-English" ||
        quizName !== "Twin Pairs"
      ) {
        return {
          level: level,
          game_master_id: gameId,
          ...(row?.quiz_id != null && { quiz_id: row?.quiz_id }),
          ...(row?.question != "" && { question: row?.question }),
          ...(row?.question_id != null && { yoruba_word_id: row?.question_id }),
          ...(row?.audio_url != "" && { audio_url: row?.audio_url }),
          ...(row?.englishHint != '' && { englishHint: row?.englishHint }),
          ...(row?.options && JSON.stringify(row?.options) !== JSON.stringify(defaultValue.options) && { options: row?.options }),
          ...(Object.keys(row?.selectedOption || {}).length > 0 && { answer: row?.options[row?.selectedOption] }),
          game_type: quizType,
        };
      } else {
        return {
          level: level,
          game_master_id: gameId,
          ...(row?.quiz_id != null && { quiz_id: row?.quiz_id }),
          ...(row?.question != "" && { question: row?.question }),
          ...(row?.question_id != null && { yoruba_word_id: row?.question_id }),
          ...(row?.audio_url != "" && { audio_url: row?.audio_url }),
          ...(row?.englishHint != '' && { englishHint: row?.englishHint }),
          ...(row?.options && JSON.stringify(row?.options) !== JSON.stringify(defaultValue.options) && { options: row?.options }),
          ...(Object.keys(row?.selectedOption || {}).length > 0 && { answer: row?.options[row?.selectedOption] }),
          game_type: quizType,
          ...(Object.keys(row?.pairs || {}).length > 0 && { pairs: row?.pairs }),
        };
      }
    });
    axiosMethod(baseUrl + endpoint, reqBody)
      .then((res) => {
        toastr.success(
          `Quiz ${isEdit ? "Edited" : "Added"} Successfully`,
          "Success"
        );
        // window.location.reload(false);
        history.push("/Quiz");
      })
      .catch((error) => {
        toastr.error(`Quiz ${isEdit ? "Edit" : "Add"} Failed`, "Error!");
      });
  }

  const fetchRemainingYorubaWords = async () => {
    axios
      .get(baseUrl + `/yoruba-word/all-dictionary`)
      .then((res) => {
        if (res && res.data.status === 200) {
          const options = res.data.data.list.map((d) => {
            const audioName = d?.audio_path
              .split("/")
              .pop()
              .replace(".mp3", "");
            return {
              id: d?.yoruba_word_id,
              label: d?.yoruba_word_name,
              value: audioName,
            };
          });
          setRemainingYorubaWordList(options);
        }
      })
      .catch((err) => {
        console.log("error");
      });
  };
  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitDialectType",
  });

  const handleOptionChange = (event, option, index) => {
    let newRow = [...rowData];
    newRow[index].selectedOption = option;
    setRowData(newRow);
  };

  const handleOptionTextChange = (event, option, index, list) => {
    if (list === "Y-E") {
      let newRow = [...rowData];
      const updatedOptions = {
        ...newRow[index].options,
        [option]: {
          word_id: null,
          word_name: event?.target?.value?.toLowerCase(),
        },
      };
      newRow[index].options = updatedOptions;
      setRowData(newRow);
    } else {
      const optionData = list.find((item) => item.label === event.target.value);
      let newRow = [...rowData];
      const updatedOptions = {
        ...newRow[index].options,
        [option]: {
          word_id: optionData?.id,
          word_name: event?.target?.value?.toLowerCase(),
          audio_name: optionData?.value,
        },
      };
      newRow[index].options = updatedOptions;
      setRowData(newRow);
    }
  };

  const handleRemoveSet = (indexToRemove, quiz_id) => {
    if (rowData?.length === 1) return;
    const newRow = [...rowData];
    newRow.splice(indexToRemove, 1);
    setRowData(newRow);
    if (quiz_id) {
      axios
        .delete(baseUrl + `/quiz/remove/${quiz_id}`)
        .then((res) => {
          if (res && res.data.status === 200) {
            toastr.success("Quiz Entry Deleted Successfully", "Success");
          }
        })
        .catch((err) => {
          toastr.error(`Quiz Entry removal Failed`, "Error!");
        });
    } else {
      console.error("Unable to determine the subscription ID for deletion");
    }
  };

  useEffect(() => {
    axios
      .get(baseUrl + "/game-master/all")
      .then((response) => {
        const fetchedGameNames = response.data.data.list
          ? response.data.data.list
          : [];
        setGameNames(fetchedGameNames);
      })
      .catch((error) => {
        console.error("Error fetching game names:", error);
      });
  }, []);

  useEffect(() => {
    fetchRemainingYorubaWords();
    axios
      .get(baseUrl + "/game-master/all")
      .then((response) => {
        const fetchedGameNames = response.data.data.list
          ? response.data.data.list
          : [];
        setGameNames(fetchedGameNames);
      })
      .catch((error) => {
        console.error("Error fetching game names:", error);
      });
  }, []);

  useEffect(() => {
    if (gameData) {
      axios
        .get(
          baseUrl +
            `/quiz/get-quiz?game_master_id=${gameData?.game_master_id}&level=${gameData?.level}&type=${gameData?.game_type}`
        )
        .then((response) => {
          const fetchedData = response.data?.data;
          if (fetchedData) {
            setLevel(fetchedData?.[0]?.level);
            setQuizType(fetchedData?.[0]?.game_type);
            setQuizName(fetchedData?.[0]?.game_name);
            setGameId(fetchedData?.[0]?.game_master_id);
            if (fetchedData.length >= 1) {
              let newRow = fetchedData.map((data) => {
                let res;
                if (data?.options) {
                  res = Object.keys(data?.options)
                    .filter((key) => {
                      const isMatch = (data?.answer?.word_name === data?.options?.[key]?.word_name) && (data?.answer?.word_id === data?.options?.[key]?.word_id);
                      return isMatch;
                    })
                    .map((key) => data?.options[key]); // Map the keys to their corresponding values
                }
                return {
                  quiz_id: data.quiz_id,
                  question: data?.question,
                  question_id: data?.yoruba_word_id,
                  audio_url: data?.audio_url,
                  englishHint: data?.englishHint,
                  options: data?.options,
                  selectedOption: res?.[0] || data?.answer,
                  pairs: data?.pairs,
                };
              });
              setRowData(newRow);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching game names:", error);
        });
    }
  }, [gameData]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: 80,
        padding: "20px 16px",
      }}
    >
      <Breadcrumbs title="Quiz" breadcrumbItem={"Add Quiz"} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%", marginBottom: "10px" }}>
          <Label className="form-label" htmlFor="demo-simple-select">
            Game Name
          </Label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={quizName}
            onChange={(e) => {
              const selectedGame = gameNames.find(
                (game) => game.game_name === e.target.value
              );
              setQuizName(e.target.value);
              setGameId(selectedGame?.game_master_id || null);
            }}
            style={{ width: "100%", height: "5.4vh", backgroundColor: "white" }}
          >
            {gameNames.map((game) => (
              <MenuItem key={game.game_name} value={game.game_name}>
                {game.game_name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          style={{ width: "100%", marginBottom: "10px", marginLeft: "10px" }}
        >
          <Label className="form-label" htmlFor="demo-simple-select">
            Quiz Type
          </Label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={quizType}
            onChange={(e) => setQuizType(e.target.value)}
            style={{ width: "100%", height: "5.4vh", backgroundColor: "white" }}
          >
            <MenuItem value={"Yoruba-English"}>Yoruba-English</MenuItem>
            <MenuItem value={"English-Yoruba"}>English-Yoruba</MenuItem>
            <MenuItem value={"Easy"}>Easy</MenuItem>
            <MenuItem value={"Medium"}>Medium</MenuItem>
            <MenuItem value={"Hard"}>Hard</MenuItem>
            <MenuItem value={"NA"}>NA</MenuItem>
          </Select>
        </div>

        <div
          style={{ width: "100%", marginBottom: "10px", marginLeft: "10px" }}
        >
          <Label className="form-label" htmlFor="demo-simple-select">
            Level
          </Label>
          <Input
            type="number"
            className="form-control"
            id="level"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
          />
        </div>
      </div>

      {quizName === "LinguaTranslate" && remainingYorubaWordList && (
        <>
          {rowData.map((row, index) => (
            <Paper
              key={index}
              elevation={3}
              style={{
                width: "100%",
                padding: "50px",
                marginTop: "20px",
                borderRadius: "8px",
                backgroundColor: "white",
                display: "flex",
                position: "relative",
                flexDirection: "column",
              }}
            >
              <CloseIcon
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveSet(index, row.quiz_id)}
              />
              <div>
                <label>Question</label>
                {quizType !== "Yoruba-English" && (
                  <Input
                    type="text"
                    style={{ width: "97.1%" }}
                    value={row?.question}
                    onChange={(e) => {
                      let newRow = [...rowData];
                      newRow[index].question = e.target.value;
                      setRowData(newRow);
                    }}
                  />
                )}

                {quizType === "Yoruba-English" && (
                  <Autocomplete
                    id="size-small-outlined-multi"
                    size="small"
                    options={remainingYorubaWordList}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(_, newValue) => {
                      let newRow = [...rowData];
                      newRow[index].question = newValue?.label;
                      newRow[index].audio_url = newValue?.value;
                      newRow[index].question_id = newValue?.id;
                      setRowData(newRow);
                    }}
                    value={row?.question}
                    filterOptions={filterOptions}
                    renderOption={(props, option) =>
                      renderOptions(props, option)
                    }
                  />
                )}
              </div>

              <div
                style={{ marginTop: 20, display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1, marginRight: 12 }}>
                  <label>Options</label>
                  <RadioGroup
                    value={row?.selectedOption.word_name}
                    onChange={(event, option) =>
                      handleOptionChange(event, option, index)
                    }
                    style={{ width: "100%" }}
                  >
                    {["A", "C"].map((option) => (
                      <FormControlLabel
                        className={classes.fontSize}
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={
                          <span style={{ width: "100%" }}>
                            {quizType !== "English-Yoruba" && (
                              <Input
                                type="text"
                                style={{ width: "100%" }}
                                placeholder={`Option ${option}`}
                                value={row?.options[option].word_name || null}
                                onChange={(event) =>
                                  handleOptionTextChange(
                                    event,
                                    option,
                                    index,
                                    "Y-E"
                                  )
                                }
                              />
                            )}
                            {quizType === "English-Yoruba" && (
                              <Autocomplete
                                id="size-small-outlined-multi"
                                size="small"
                                options={remainingYorubaWordList}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(_, newValue) => {
                                  let e = {};
                                  e.target = { value: newValue?.label };
                                  handleOptionTextChange(
                                    e,
                                    option,
                                    index,
                                    remainingYorubaWordList
                                  );
                                }}
                                value={row?.options[option].word_name || null}
                                filterOptions={filterOptions}
                                renderOption={(props, option) =>
                                  renderOptions(props, option)
                                }
                              />
                            )}
                          </span>
                        }
                      />
                    ))}
                  </RadioGroup>
                </div>

                <div style={{ flex: 1, marginLeft: 12 }}>
                  <label>&nbsp;</label>
                  <RadioGroup
                    value={row?.selectedOption.word_name}
                    onChange={(event, option) =>
                      handleOptionChange(event, option, index)
                    }
                  >
                    {["B", "D"].map((option) => (
                      <FormControlLabel
                        className={classes.fontSize}
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={
                          <div>
                            {quizType !== "English-Yoruba" && (
                              <Input
                                type="text"
                                style={{ width: "100%" }}
                                placeholder={`Option ${option}`}
                                value={row?.options[option].word_name || null}
                                onChange={(event) =>
                                  handleOptionTextChange(
                                    event,
                                    option,
                                    index,
                                    "Y-E"
                                  )
                                }
                              />
                            )}
                            {quizType === "English-Yoruba" && (
                              <Autocomplete
                                id="size-small-outlined-multi"
                                size="small"
                                options={remainingYorubaWordList}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(_, newValue) => {
                                  let e = {};
                                  e.target = { value: newValue?.label };
                                  handleOptionTextChange(
                                    e,
                                    option,
                                    index,
                                    remainingYorubaWordList
                                  );
                                }}
                                value={row?.options[option].word_name || null}
                                filterOptions={filterOptions}
                                renderOption={(props, option) =>
                                  renderOptions(props, option)
                                }
                              />
                            )}
                          </div>
                        }
                      />
                    ))}
                  </RadioGroup>
                </div>
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>Answer :</label>
                <Input
                  type="text"
                  value={
                    row?.options[row?.selectedOption]?.word_name ||
                    row?.selectedOption?.word_name ||
                    null
                  }
                  style={{
                    padding: "10px",
                    width: "39.4%",
                    fontSize: "17px",
                    fontWeight: "600",
                  }}
                />
              </div>
            </Paper>
          ))}
          <div className="mt-4" style={{ textAlign: "center" }}>
            <Button
              color="primary"
              variant="contained"
              className="w-md"
              onClick={() => {
                let newRow = [...rowData];
                newRow.push({ ...defaultValue });
                setRowData(newRow);
              }}
            >
              Add More
            </Button>
          </div>
        </>
      )}
      {/* ODD ONE OUT GAME BELOW */}
      {quizName !== "LinguaTranslate" && quizName !== "Twin Pairs" && (
        <>
          {rowData.map((row, index) => (
            <Paper
              key={index}
              elevation={3}
              style={{
                width: "100%",
                padding: "50px",
                marginTop: "20px",
                borderRadius: "8px",
                backgroundColor: "white",
                display: "flex",
                position: "relative",
                flexDirection: "column",
              }}
            >
              <CloseIcon
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveSet(index, row.quiz_id)}
              />
              <div>
                <label>First Hint (Yoruba Group Name)</label>
                <Autocomplete
                  id="size-small-outlined-multi"
                  size="small"
                  options={remainingYorubaWordList}
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(_, newValue) => {
                    let newRow = [...rowData];
                    newRow[index].question = newValue?.label;
                    newRow[index].audio_url = newValue?.value;
                    newRow[index].question_id = newValue?.id;
                    setRowData(newRow);
                  }}
                  value={row?.question}
                  filterOptions={filterOptions}
                  renderOption={(props, option) =>
                    renderOptions(props, option)
                  }
                />
              </div>
              <div>
                <label>
                  Second Hint (English Translation of Yoruba Group Name)
                </label>
                <Input
                  type="text"
                  style={{ width: "100%" }}
                  value={row?.englishHint}
                  onChange={(e) => {
                    let newRow = [...rowData];
                    newRow[index].englishHint = e.target.value;
                    setRowData(newRow);
                  }}
                />
              </div>

              <div
                style={{ marginTop: 20, display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1, marginRight: 12 }}>
                  <label>Options</label>
                  <RadioGroup
                    value={row?.selectedOption.word_name}
                    onChange={(event, option) =>
                      handleOptionChange(event, option, index)
                    }
                    style={{ width: "100%" }}
                  >
                    {["A", "C", "E"].map((option) => (
                      <FormControlLabel
                        className={classes.fontSize}
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={
                          <span style={{ width: "100%" }}>
                            <Autocomplete
                              id="size-small-outlined-multi"
                              size="small"
                              options={remainingYorubaWordList}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(_, newValue) => {
                                let val = {};
                                val.target = { value: newValue?.label };
                                handleOptionTextChange(
                                  val,
                                  option,
                                  index,
                                  remainingYorubaWordList
                                );
                              }}
                              value={row?.options[option]?.word_name || null}
                              filterOptions={filterOptions}
                              renderOption={(props, option) =>
                                renderOptions(props, option)
                              }
                            />
                          </span>
                        }
                      />
                    ))}
                  </RadioGroup>
                </div>

                <div style={{ flex: 1, marginLeft: 12 }}>
                  <label>&nbsp;</label>
                  <RadioGroup
                    value={row?.selectedOption.word_name}
                    onChange={(event, option) =>
                      handleOptionChange(event, option, index)
                    }
                  >
                    {["B", "D"].map((option) => (
                      <FormControlLabel
                        className={classes.fontSize}
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={
                          <div>
                            <Autocomplete
                              id="size-small-outlined-multi"
                              size="small"
                              options={remainingYorubaWordList}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(_, newValue) => {
                                let e = {};
                                e.target = { value: newValue.label };
                                handleOptionTextChange(
                                  e,
                                  option,
                                  index,
                                  remainingYorubaWordList
                                );
                              }}
                              value={row?.options[option]?.word_name || null}
                              filterOptions={filterOptions}
                              renderOption={(props, option) =>
                                renderOptions(props, option)
                              }
                            />
                          </div>
                        }
                      />
                    ))}
                  </RadioGroup>
                </div>
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>Answer :</label>
                <Input
                  type="text"
                  value={
                    row?.options[row?.selectedOption]?.word_name ||
                    row?.selectedOption?.word_name ||
                    null
                  }
                  style={{
                    padding: "10px",
                    width: "39.4%",
                    fontSize: "17px",
                    fontWeight: "600",
                  }}
                />
              </div>
            </Paper>
          ))}
          <div className="mt-4" style={{ textAlign: "center" }}>
            <Button
              color="primary"
              variant="contained"
              className="w-md"
              onClick={() => {
                let newRow = [...rowData];
                newRow.push({ ...defaultValue });
                setRowData(newRow);
              }}
            >
              Add More
            </Button>
          </div>
        </>
      )}

      {quizName === "Twin Pairs" && (
        <>
          {rowData.map((row, i) => (
            <Paper
              key={i}
              elevation={3}
              style={{
                width: "100%",
                padding: "50px",
                marginTop: "20px",
                borderRadius: "8px",
                backgroundColor: "white",
                display: "flex",
                position: "relative",
                flexDirection: "column",
              }}
            >
              <CloseIcon
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveSet(i, row.quiz_id)}
              />
              {row.pairs &&
                Array(5)
                  .fill()
                  .map((key, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", marginTop: "10px" }}
                    >
                      <div style={{ flex: 1, marginRight: "20px" }}>
                        <label>{`Key ${index + 1}`}</label>
                        <Autocomplete
                          id="size-small-outlined-multi"
                          size="small"
                          options={remainingYorubaWordList}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(_, newValue) => {
                            let newRow = [...rowData];
                            newRow[i].pairs[`key${index}`] = {
                              word_id: newValue?.id,
                              word_name: newValue?.label,
                              audio_name: newValue?.value,
                            };
                            setRowData(newRow);
                          }}
                          value={row?.pairs[`key${index}`]?.word_name || ""}
                          filterOptions={filterOptions}
                          renderOption={(props, option) =>
                            renderOptions(props, option)
                          }
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label>{`Value ${index + 1}`}</label>
                        <Autocomplete
                          id="size-small-outlined-multi"
                          size="small"
                          options={remainingYorubaWordList}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(_, newValue) => {
                            let newRow = [...rowData];
                            newRow[i].pairs[`value${index}`] = {
                              word_id: newValue?.id,
                              word_name: newValue?.label,
                              audio_name: newValue?.value,
                            };
                            setRowData(newRow);
                          }}
                          value={row?.pairs[`value${index}`]?.word_name || ""}
                          filterOptions={filterOptions}
                          renderOption={(props, option) =>
                            renderOptions(props, option)
                          }
                        />
                      </div>
                    </div>
                  ))}
            </Paper>
          ))}
          <div className="mt-4" style={{ textAlign: "center" }}>
            <Button
              color="primary"
              variant="contained"
              className="w-md"
              onClick={() => {
                let newRow = [...rowData];
                newRow.push({ ...defaultValue, pairs: {} });
                setRowData(newRow);
              }}
            >
              Add More
            </Button>
          </div>
        </>
      )}

      {!(props.data && !props.editQuiz) && (
        <div className="mt-4">
          <Button
            id="submitDialectType"
            color="primary"
            style={{ marginBottom: "40px" }}
            variant="contained"
            className="w-md"
            onClick={() => {
              handleValidation();
            }}
          >
            Submit
          </Button>
          <Button
            style={{ marginBottom: "40px", marginLeft: "10px" }}
            className="w-md"
            onClick={() => {
              history.push("/Quiz");
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((style) => ({
  fontSize: {
    "& span:last-child": {
      width: "100%",
    },
  },
}));

export default QuizDialog;