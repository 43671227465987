import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link, useHistory } from 'react-router-dom';

// import images
import logo from '../../assets/images/sylo_logo.png';
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { baseUrl } from '../../helpers/EncryptionHelper';

const Register = (props) => {
  const history = useHistory();
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    // console.log('====================================');
    // console.log(values);
    // console.log('====================================');
    // props.registerUser(values);

    // let encEmail = encrypt(values?.email);
    let encPass = values?.password;
    // let encPhone = encrypt(values?.phone);

    axios
      .post(baseUrl + '/users/admin-create', {
        email: values?.email,
        password: encPass,
        name: values.name,
      })
      .then((res) => {
        toastr.success('Registered Successfully', 'Success');
        // axios.defaults.headers.common['token'] =
        //   res.data?.data?.rows?.[0]?.token;

        // sessionStorage.setItem(
        //   JSON.stringify({ authUser: res.data.data?.rows?.[0] })
        // );
        history.push('/login');
      })
      .catch((error) => {
        console.log('====================================');
        console.log(error.response);
        console.log('====================================');
        toastr.error('Unable to create account', 'Error!');
        // setError({
        //   type: 'warning',
        //   text:
        //     error?.response?.data?.data?.message ||
        //     'Server Error, Please try again Later!',
        // });
      });
  };

  useEffect(() => {
    props.apiError('');
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <React.Fragment>
      <div className='account-pages my-2 pt-sm-3'>
        <Container>
          <Row>
            <Col lg={12}>
              <div className='text-center'>
                <Link to='/' className='mb-5 d-block auth-logo'>
                  <img
                    src={logo}
                    alt=''
                    height='48'
                    className='logo logo-dark'
                  />
                  {/* <img
                    src={logolight}
                    alt=''
                    height='22'
                    className='logo logo-light'
                  /> */}
                </Link>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className='p-4'>
                  <div className='text-center mt-2'>
                    <h5 className='text-primary'>Register Account</h5>
                    <p className='text-muted'>
                      Register to get your Dictionary account now.
                    </p>
                  </div>
                  <div className='p-2 mt-4'>
                    <AvForm
                      className='form-horizontal'
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.user && props.user ? (
                        <Alert color='success'>
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {props.registrationError && props.registrationError ? (
                        <Alert color='danger'>{props.registrationError}</Alert>
                      ) : null}
                      <div className='mb-3'>
                        <AvField
                          name='name'
                          label='Name'
                          type='text'
                          required
                          placeholder='Enter name'
                        />
                      </div>
                      <div className='mb-3'>
                        <AvField
                          id='email'
                          name='email'
                          label='Email'
                          className='form-control'
                          placeholder='Enter email'
                          type='email'
                          required
                        />
                      </div>

                      <div className='mb-3'>
                        <AvField
                          name='password'
                          label='Password'
                          type='password'
                          required
                          value=''
                          placeholder='Enter Password'
                        />
                      </div>

                      <div className='mt-3 text-end'>
                        <button
                          className='btn btn-primary w-sm waves-effect waves-light'
                          type='submit'
                        >
                          Register
                        </button>
                      </div>

                      <div className='mt-4 text-center'>
                        <p className='text-muted mb-0'>
                          Already have an account ?{' '}
                          <Link to='/login' className='fw-medium text-primary'>
                            {' '}
                            Login
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className='mt-5 text-center'>
                <p>
                  © {new Date().getFullYear()} Dictionary. Crafted with{' '}
                  <i className='mdi mdi-heart text-danger'></i> in India
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
};

export default Register;
