import { Dialog } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Input, Label } from 'reactstrap';

import { baseUrl } from '../../helpers/EncryptionHelper';
import useEnterKeyListener from '../../helpers/useEnterKeyListener';
import { Box, FormControl, InputLabel, MenuItem, Select, TextareaAutosize } from '@mui/material';
const SubscriptionDialog = ({
  data,
  visible,
  handleClose,
  Transition,
  editSubscriptionPlan,
}) => {
  const [name, setName] = useState('');
  const [interval, setInterval] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [currency, setCurrency] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [previousamount, setPreviousAmount] = React.useState('');


  function handleValidation() {
    let errorText;
    if (!name) {
      errorText = 'Please enter WordType';
    }
    if (errorText) {
      toastr.error(errorText, 'Error!');
    } else {
      handleAddEditWordType(editSubscriptionPlan);
    }
  }

  function handleAddEditWordType(isEdit) {
    let endpoint = isEdit
      ? `/subscription/edit/${data?.subscription_id}`
      : '/subscription/add-subscription';
    let axiosMethod = isEdit ? axios.put : axios.post;
    axiosMethod(baseUrl + endpoint, {
      subscription_name: name,
      interval_period: interval,
      amount: amount,
      currency: currency,
      desciption: description, 
      previousamount:previousamount
    })
      .then((res) => {
        toastr.success(
          `Subscription ${isEdit ? 'Edited' : 'Added'} Successfully`,
          'Success'
        );
        window.location.reload(false);
      })
      .catch((error) => {
        toastr.error(
          `Subscription ${isEdit ? 'Edit' : 'Add'} Failed`,
          'Error!'
        );
      });
  }
  

  useEnterKeyListener({
    querySelectorToExecuteClick: '#submitDialectType',
  });

  useEffect(() => {
    if (data && editSubscriptionPlan) {
      // If it's an edit and data is available, prefill the form
      setName(data?.subscription_name || '');
      setInterval(data?.interval_period || '');
      setAmount(data?.amount || '');
      setCurrency(data?.currency || '');
      setDescription(data?.description || '');
    } else {
      // If it's not an edit or no data is available, reset the form
      setName('');
      setInterval('');
      setAmount('');
      setCurrency('');
      setDescription('');
    }
  }, [data, editSubscriptionPlan]);
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{marginTop:'5%'}}
    >
      <div style={{ width: 600, padding: 40}}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
       <div className='mb-3' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ marginRight: '10px', flex: 1}}>
            <Label className='form-label' htmlFor='addDialectTypeInput'>
              Name
            </Label>
            <Input
              type='text'
              className='form-control'
              id='addDialectTypeInput'
              value={name}
              disabled={data && !editSubscriptionPlan}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div style={{ flex: 1, marginLeft: '10px'}}>
            <Label className='form-label' htmlFor='demo-simple-select'>
              Interval
            </Label>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={interval}
              onChange={(e) => setInterval(e.target.value)}
              style={{ width: '100%', height: '5.4vh' }} // Adjusted width
            >
              <MenuItem value={"hourly"}> Hourly </MenuItem>
              <MenuItem value={"daily"}> Daily</MenuItem>
              <MenuItem value={"weekly"}> Weekly </MenuItem>
              <MenuItem value={"monthly"}> Monthly  </MenuItem>
              <MenuItem value={"quarterly"}> 3 Months</MenuItem>
              <MenuItem value={"quarterly"}> 6 Months</MenuItem>
              <MenuItem value={"annually"}> Annually </MenuItem>
            </Select>
          </div>
        </div>
        <div className='mb-3' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ marginRight: '10px', flex: 1}}>
          <Label className='form-label' htmlFor='formrow-firstname-input'>
           Current Amount
          </Label>
          <Input
            type='number'
            className='form-control'
            id='addDialectTypeInput'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div style={{ flex: 1, marginLeft: '10px'}}>
        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
      <Label className='form-label' htmlFor='formrow-firstname-input'>
      Previous Amount
          </Label>
        {/* <InputLabel id="demo-simple-select-label">Interval</InputLabel> */}
        <Input
            type='number'
            className='form-control'
            id='addDialectTypeInput'
            value={previousamount}
            onChange={(e) => setPreviousAmount(e.target.value)}
          />
        {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currency}
          label="Interval"
          onChange={(e) => setCurrency(e.target.value)}
          style={{ width: '100%', height:'5.4vh'}} // Adjusted width

        >
          <MenuItem value={"USD"}>USD</MenuItem>
          <MenuItem value={"NGN"}>NGN</MenuItem>
        </Select> */}
      </FormControl>
    </Box>
        </div>
        </div>
         <div style={{ marginRight: '10px', flex: 1}}>
          <Label className='form-label' htmlFor='formrow-firstname-input'>
          Currency
          </Label>
          <Input
              type='text'
              className='form-control'
              id='addDialectTypeInput'
              value={currency}
              disabled={data && !editSubscriptionPlan}
              onChange={(e) => setCurrency(e.target.value)}
            />
        </div>
        <div>
  <Label className='form-label' htmlFor='formrow-firstname-input'>
    Description
  </Label>
  <TextareaAutosize
    style={{ width: '100%', height: '5.4vh' }}
    value={description}
    onChange={(e) => setDescription(e.target.value)}
  />
</div>
        {!(data && !editSubscriptionPlan) && (
          <div className='mt-4'>
            <Button
              id='submitDialectType'
              color='primary'
              variant='contained'
              className='w-md'
              onClick={() => {
                handleValidation();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default SubscriptionDialog;
