import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import BootstrapTable from 'react-bootstrap-table-next';
// import projectColumns from './projectColumns';
import SimpleBar from 'simplebar-react';

const MyProfile = (props) => {
  const { userProfile, onGetUserProfile } = props;
  // eslint-disable-next-line no-unused-vars
  const [miniCards, setMiniCards] = useState([
    {
      title: 'Completed Projects',
      iconClass: 'bx-check-circle',
      text: '125',
    },
    { title: 'Pending Projects', iconClass: 'bx-hourglass', text: '12' },
    { title: 'Total Revenue', iconClass: 'bx-package', text: '$36,524' },
  ]);

  const [activeTab, setActiveTab] = useState('1');

  const profiletoggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let user = JSON.parse(sessionStorage.getItem('authUser'));

  //   useEffect(() => {
  //     onGetUserProfile();
  //   }, [onGetUserProfile]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title='Contacts' breadcrumbItem='Profile' />

          <Row>
            <Col xl='12'>
              <Card className='card h-100'>
                <CardBody>
                  <div className='text-center'>
                    <UncontrolledDropdown className='float-end'>
                      <DropdownToggle
                        tag='a'
                        className='text-body font-size-16'
                        caret
                      >
                        <i className='uil uil-ellipsis-h'></i>
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-menu-end'>
                        <DropdownItem href='#'>Edit</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className='clearfix'></div>
                    <div>
                      {user?.img ? (
                        <img
                          src={user?.img}
                          alt=''
                          className='avatar-lg rounded-circle img-thumbnail'
                        />
                      ) : (
                        <div className='avatar-lg mx-auto mb-4'>
                          <div className='avatar-title bg-soft-primary rounded-circle text-primary'>
                            <i className='mdi mdi-account-circle display-4 m-0 text-primary'></i>
                          </div>
                        </div>
                      )}
                    </div>
                    <h5 className='mt-3 mb-1'>{user?.name || 'NA'}</h5>
                    <p className='text-muted'>
                      {user?.designation || 'Account Owner'}
                    </p>
                  </div>
                  <hr className='my-4' />

                  <div className='text-muted'>
                    <h5 className='font-size-16'>About</h5>
                    <p>NA</p>
                    <div className='table-responsive mt-4'>
                      <div>
                        <p className='mb-1'>Name :</p>
                        <h5 className='font-size-16'>{user?.name || 'NA'}</h5>
                      </div>
                      <div className='mt-4'>
                        <p className='mb-1'>Mobile :</p>
                        <h5 className='font-size-16'>{user?.mobile || 'NA'}</h5>
                      </div>
                      <div className='mt-4'>
                        <p className='mb-1'>E-mail :</p>
                        <h5 className='font-size-16'>{user?.email || 'NA'}</h5>
                      </div>
                      <div className='mt-4'>
                        <p className='mb-1'>Location :</p>
                        <h5 className='font-size-16'>
                          {[user?.city, user?.state].join(', ')}
                        </h5>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

MyProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

export default MyProfile;
