import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import EnhancedTableHead, {
  Transition,
  getComparator,
  stableSort,
} from '../../components/Common/EnhancedTableHead';

import { Button, Slide } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import AddDialectTypeDialog from './AddDialectTypeDialog';
import DeleteDailog from '../../components/Common/DeleteDailog';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { baseUrl } from '../../helpers/EncryptionHelper';
import LoadIndicator from '../../components/Common/LoadIndicator';
import CustomSearchBar from '../../components/Common/CustomSearchBar';

const headCells = [
  {
    id: 'dialect_type_name',
    numeric: false,
    disablePadding: false,
    label: 'Dialect Type Name',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    align: 'center',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

// process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_BACKEND_ROUTE;

export default function CustomTableDialectType() {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openDailog, setOpenDailog] = useState(false);
  const [openDeleteDailog, setOpenDeleteDailog] = useState(false);
  const [editDialectType, setEditDialectType] = useState(false);
  const [selectedDialectType, setselectedDialectType] = useState(false);

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [showLoader, setShowLoader] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClose = () => {
    setEditDialectType(false);
    setselectedDialectType();
    setOpenDailog(false);
  };

  function handleCloseDeleteDailog() {
    setselectedDialectType();
    setOpenDeleteDailog(false);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setOffset((s) => s + limit)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDelete = async (id) => {
    axios
      .delete(baseUrl + `/dialect-type/remove/${id}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          handleCloseDeleteDailog();
          toastr.success('Dialect Type Deleted Successfully', 'Success');

          fetchData(0, limit);
          setOffset(0);
        }
      })
      .catch((err) => {
        toastr.error(`Dialect Type removal Failed`, 'Error!');
      });
  };

  const handleCloseAndRefresh = () => {
    setOpenDailog(false);
    setSelected([]);
    setTimeout(() => {
      fetchData(0, limit);
      setOffset(0);
    }, 200);
  };

  const fetchData = async (offset, limit) => {
    axios
      .get(baseUrl + `/dialect-type/all?limt=${limit}&offset=${offset}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          setRows(res.data.data.list);
          setTotalCount(parseInt(res.data.data?.list?.length));
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log('error');
        setShowLoader(false);
      });
  };

  useEffect(() => {
    fetchData(offset, limit);
  }, [limit, offset]);

  const ITEMS_PER_PAGE = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const currentData = rows.slice(startIndex, endIndex);

  return (
    <div className={classes.root}>
      <Button
        style={{
          backgroundColor: '#257b59',
          color: 'white',
          padding: 10,
          alignSelf: 'flex-end',
          marginBottom: 20,
        }}
        onClick={() => {
          setselectedDialectType();
          setOpenDailog(true);
        }}
      >
        Add Dialect Type
      </Button>
      <Paper className={classes.paper}>
        {showLoader ? (
          <LoadIndicator />
        ) : (
          <>
            <CustomSearchBar
              rows={rows}
              setRows={setRows}
              keysToSearch={['dialect_type_name']}
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby='tableTitle'
                size={dense ? 'small' : 'medium'}
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                {currentData.map((row, index) =>  {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align='left'>
                            {row.dialect_type_name}
                          </TableCell>
                          <TableCell width={'30%'} align='center'>
                            <IconButton
                              variant='contained'
                              color='#ddd'
                              onClick={() => {
                                setselectedDialectType(row);
                                setOpenDailog(true);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                            <IconButton
                              variant='contained'
                              color='#ddd'
                              onClick={() => {
                                setEditDialectType(true);
                                setselectedDialectType(row);
                                setOpenDailog(true);
                              }}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              variant='contained'
                              color='#ddd'
                              onClick={() => {
                                setOpenDeleteDailog(true);
                                setselectedDialectType(row);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component='div'
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChange}
              color="primary"
              variant="outlined" shape="rounded" 
            />
      </Stack>
      </div>
          </>
        )}
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label='Dense padding'
      />

      {openDailog && (
        <AddDialectTypeDialog
          data={selectedDialectType}
          visible={openDailog}
          handleClose={handleClose}
          Transition={Transition}
          editDialectType={editDialectType}
        />
      )}
      {openDeleteDailog && (
        <DeleteDailog
          data={selectedDialectType}
          visible={openDeleteDailog}
          handleClose={handleCloseDeleteDailog}
          Transition={Transition}
          dialogTitle={() => (
            <div className='mt-3'>
              Do you want to delete this Dialect Type :{' '}
              <strong>{selectedDialectType?.dialect_type_name || 'NA'}</strong>
            </div>
          )}
          handleDeleteClicked={(data) => handleDelete(data?.dialect_type_id)}
        />
      )}
    </div>
  );
}
