import { Dialog } from '@material-ui/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Input, Label } from 'reactstrap';

import { baseUrl } from '../../helpers/EncryptionHelper';
import useEnterKeyListener from '../../helpers/useEnterKeyListener';

const AddYorubaWordDialog = ({
  data,
  visible,
  handleClose,
  Transition,
  editYorubaWord,
}) => {
  const [word, setWord] = useState('');

  function handleValidation() {
    let errorText;
    if (!word) {
      errorText = 'Please enter YorubaWord';
    }
    if (errorText) {
      toastr.error(errorText, 'Error!');
    } else {
      handleAddEditYorubaWord(editYorubaWord);
    }
  }

  function handleAddEditYorubaWord(isEdit) {
    let endpoint = isEdit
      ? `/yoruba-word/edit/${data?.yoruba_word_id}`
      : '/yoruba-word/add-yoruba';
    let axiosMethod = isEdit ? axios.put : axios.post;
    axiosMethod(baseUrl + endpoint, {
      yoruba_word_name: word,
    })
      .then((res) => {
        toastr.success(
          `YorubaWord ${isEdit ? 'Edited' : 'Added'} Successfully`,
          'Success'
        );
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        toastr.error(`YorubaWord ${isEdit ? 'Edit' : 'Add'} Failed`, 'Error!');
      });
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: '#yorubaWordSubmit',
  });

  useEffect(() => {
    if (data) {
      setWord(data?.yoruba_word_name);
    }
  }, [data]);
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div style={{ width: 400, padding: 40 }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
        <div className='mb-3'>
          <Label className='form-label' htmlFor='formrow-firstname-input'>
            Name
          </Label>
          <Input
            type='text'
            className='form-control'
            id='yorubaWordInput'
            value={word}
            disabled={data && !editYorubaWord}
            onChange={(e) => setWord(e.target.value)}
            onFocus={() => (window.focusedInput = 'yorubaWordInput')}
          />
        </div>
        {!(data && !editYorubaWord) && (
          <div className='mt-4'>
            <Button
              id='yorubaWordSubmit'
              color='primary'
              variant='contained'
              className='w-md'
              onClick={() => {
                handleValidation();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default AddYorubaWordDialog;
