import PropTypes from 'prop-types';
import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import ReactApexChart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';

const MiniWidget = (props) => {
  const history = useHistory();
  let dashboardData = props.dashboardData;
  function redirectMe(key) {
    let path = ''
    if (key.includes('users'))
      path = '/user'
    else if (key.includes('dictionary'))
      path = '/dictionary-word'
    else if (key.includes('english'))
      path = '/english-word'
    else if (key.includes('dialect'))
      path = '/dialect-type'
    else if (key.includes('yoruba'))
      path = '/yoruba-word'

    history.push({
      pathname: path
    });
  }
  return (
    <React.Fragment>
      {Object.keys(dashboardData).map((key) => (
        <Col md={6} xl={3} key={key} onClick={() => redirectMe(key)}>
          <Card>
            <CardBody >
              <div className='float-end mt-2'></div>
              <div>
                <h4 className='mb-4 mt-4'>
                  <span>{getText(key)}</span>
                </h4>
                <p className='text-muted mb-0' style={{ fontSize: 32 }}>
                  {dashboardData[key]}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default MiniWidget;

function getText(key) {
  switch (key) {
    case 'yoruba_words':
      return 'Yoruba Words';
    case 'dictionary':
      return 'Dictionary Words';
    case 'dialects':
      return 'Dialects';
    case 'english_words':
      return 'English Words';
    case 'users':
      return 'Users';
    case 'word-of-the-day':
      return 'Word Of The Day';
    case 'subscription-Plans':
      return 'Subscription Plans';
    case 'Game-Master':
      return 'Game Master';
    case 'Quiz':
      return 'Quiz';
    default:
      return 'NA';
  }
}

MiniWidget.propTypes = {
  reports: PropTypes.array,
};
