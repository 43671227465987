import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Row, Col, Alert, Container, CardBody, Card } from 'reactstrap';

// Redux
import { withRouter, Link, useHistory } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import logo from '../../assets/images/dictionary.png';

import axios from 'axios';
import { baseUrl } from '../../helpers/EncryptionHelper';

// const ENCRYPTION_KEY = 'NuX4UQ9ZjMiOWLewavCpt2PyP6ojWuLv'; // Must be 256 bits (32 characters)
// const IV_LENGTH = 16;

const Login = (props) => {
  const [error, setError] = useState();
  const history = useHistory();

  const handleValidSubmit = (event, values) => {
    //added temporarily
    console.log({ history });
    // axios.defaults.headers.common['token'] = "temp_token";
    // sessionStorage.setItem('authUser', JSON.stringify({username : 'azam', email : 'azam@test.com'}));
    // history.push('/dashboard');

    axios
      .post(baseUrl + '/users/admin-login-email', {
        email: values?.email,
        password: values?.password,
      })
      .then((res) => {
        axios.defaults.headers.common['token'] = res.data?.data?.token;

        sessionStorage.setItem('authUser', JSON.stringify(res.data.data));
        history.push('/dashboard');
      })
      .catch((error) => {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
        setError({
          type: 'warning',
          text:
            error?.response?.data?.data?.message ||
            'Server Error, Please try again Later!',
        });
      });
  };

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <React.Fragment>
      <div className='account-pages my-5 pt-sm-5'>
        <Container>
          <Row>
            <Col lg={12}>
              <div className='text-center'>
                <Link
                  to='/'
                  className='mb-5 d-block auth-logo justify-content-center align-items-center'
                >
                  <img
                    src={logo}
                    alt=''
                    height='48'
                    className='logo logo-dark'
                  />
                  <h3 className='mt-1'>Admin</h3>
                  {/* <img
                    src={logolight}
                    alt=''
                    height='22'
                    className='logo logo-light'
                  /> */}
                </Link>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className='p-4'>
                  <div className='text-center mt-2'>
                    <h5 className='text-primary'>Welcome Back !</h5>
                    <p className='text-muted'>
                      Sign in to continue to Dictionary Admin.
                    </p>
                  </div>
                  <div className='p-2 mt-4'>
                    <AvForm
                      className='form-horizontal'
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && typeof props.error === 'string' ? (
                        <Alert color='danger'>{props.error}</Alert>
                      ) : null}

                      <div className='mb-3'>
                        <AvField
                          name='email'
                          label='Email'
                          value=''
                          className='form-control'
                          placeholder='Enter email'
                          type='email'
                          required
                          onChange={() => setError()}
                        />
                      </div>

                      <div className='mb-3'>
                        <div className='float-end'>
                          {/* <Link to='/forgot-password' className='text-muted'>
                            Forgot password?
                          </Link> */}
                        </div>
                        <AvField
                          name='password'
                          label='Password'
                          value=''
                          type='password'
                          required
                          placeholder='Enter Password'
                          onChange={() => setError()}
                        />
                      </div>

                      {/* <div className='form-check'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='customControlInline'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='customControlInline'
                        >
                          Remember me
                        </label>
                      </div> */}
                      {error && (
                        <Alert color={error?.type || 'danger'} role='alert'>
                          {error?.text || 'ERROR!'}
                        </Alert>
                      )}

                      <div className='mt-4'>
                        <button
                          className='btn btn-primary w-100 waves-effect waves-light'
                          type='submit'
                        >
                          Log In
                        </button>
                      </div>

                      {/* <div className='mt-4 text-center'>
                        <h5 className='font-size-14 mb-3'>Sign in with</h5>

                        <ul className='list-inline'>
                          <li className='list-inline-item'>
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={(renderProps) => (
                                <Link
                                  to='#'
                                  className='social-list-item bg-primary text-white border-primary'
                                  onClick={renderProps.onClick}
                                >
                                  <i className='mdi mdi-facebook' />
                                </Link>
                              )}
                            />
                          </li>
                          <li className='list-inline-item'>
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={(renderProps) => (
                                <Link
                                  to='#'
                                  className='social-list-item bg-danger text-white border-danger'
                                  onClick={renderProps.onClick}
                                >
                                  <i className='mdi mdi-google' />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                          </li>
                        </ul>
                      </div> */}

                      {/* <div className='mt-4 text-center'>
                        <p className='mb-0'>
                          Don't have an account ?{' '}
                          <a
                            href='/register'
                            className='fw-medium text-primary'
                          >
                            {' '}
                            Signup now{' '}
                          </a>{' '}
                        </p>
                      </div> */}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className='mt-5 text-center'>
                <p>
                  © {new Date().getFullYear()} Dictionary. Crafted with{' '}
                  <i className='mdi mdi-heart text-danger'></i> in India
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};
