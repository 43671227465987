import PropTypes from 'prop-types';
import React from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { userRoutes, authRoutes } from './routes/allRoutes';
import Authmiddleware from './routes/middleware/Authmiddleware';
import VerticalLayout from './components/VerticalLayout';
import NonAuthLayout from './components/NonAuthLayout';
import './assets/scss/theme.scss';
import axios from 'axios';

const App = (props) => {
  let user = JSON.parse(sessionStorage.getItem('authUser'));
  if (user) {
    axios.defaults.headers.common['token'] = user.token;
  }

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
