import { Dialog } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Col, Row } from 'reactstrap';

export default function DeleteDailog({
  data,
  visible,
  handleClose,
  Transition,
  dialogTitle = () => {},
  handleDeleteClicked,
}) {
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth='xs'
    >
      <div style={{ width: '100%', padding: 40 }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
        {dialogTitle()}
        <Row className='pt-3'>
          <Col lg='6'>
            <div className='mb-2 justify-content-sm-center d-flex'>
              <Button
                color='danger'
                variant='contained'
                className='w-md bg-danger.bg-soft'
                onClick={() => handleClose()}
              >
                No
              </Button>
            </div>
          </Col>
          <Col lg='6'>
            <div className='mb-2 justify-content-sm-center d-flex'>
              <Button
                color='primary'
                variant='contained'
                className='w-md'
                onClick={() => handleDeleteClicked(data)}
              >
                Yes
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Dialog>
  );
}
