import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const SidebarContent = (props) => {
  const ref = useRef();
  const [links, setLinks] = React.useState([]);
  const sidebarLinks = [
    {
      path: '/user',
      name: 'Users',
      id: 'users',
    },
    {
      path: '/word-type',
      name: 'Word Type',
      id: 'wordType',
    },
    {
      path: '/english-word',
      name: 'English Word',
      id: 'englishWord',
    },
    {
      path: '/yoruba-word',
      name: 'Yoruba Word',
      id: 'yorubaWord',
    },
    {
      path: '/dictionary-word',
      name: 'Dictionary Word(Yoruba Word)',
      id: 'dictionaryWord',
    },
    {
      path: '/dialect-type',
      name: 'Dialect Type',
      id: 'dialectType',
    },
    {
      path: '/word-of-the-day',
      name: 'Word Of The Day',
      id: 'wordOfDay',
    },
    {
      path: '/subscription-Plans',
      name: 'Subscription Plans',
      id: 'subscriptionPlans',
    },
    {
      path: '/Game-Master',
      name: 'Game Master',
      id: 'gameMaster',
    },
    {
      path: '/Quiz',
      name: 'Quiz',
      id: 'quiz',
    },
  ];

  let userData = sessionStorage.getItem('authUser');
  userData = userData && JSON.parse(userData);

  let userRoles = userData.role;

  function getAssignedLinks() {
    let assignedLinks = [];
    if (userRoles?.includes('all')) {
      assignedLinks = sidebarLinks;
      return assignedLinks;
    }
    sidebarLinks.forEach((link) => {
      if (userRoles?.includes(link?.id)) {
        assignedLinks.push(link);
      }
    });
    return assignedLinks;
  }

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    new MetisMenu('#side-menu');
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(() => {
    setLinks(getAssignedLinks());
  }, []);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: '100%' }}
        ref={ref}
        className='sidebar-menu-scroll'
      >
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            <li>
              <Link to='/#' className='waves-effect'>
                <span>{'Dashboard'}</span>
              </Link>
            </li>
            {links.map((link) => (
              <li key={link.id}>
                <Link to={link.path} className='waves-effect'>
                  <span>{link.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
