import React, { useState } from 'react';
import SearchBar from 'material-ui-search-bar'; // Import your search bar library

const SearchBarAPI = ({value, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (searchedVal) => {
    setSearchTerm(searchedVal);
    onSearch(searchedVal);
  };
  function cancelSearch() {
    setSearchTerm('');
    onSearch('');
  }

  return (
     <SearchBar
     id='searchWords'
     value={value}
     onChange={(searchVal) => handleInputChange(searchVal)}
     onCancelSearch={() => cancelSearch()}
     style={{
       boxShadow:
         '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15)',
     }}
   />
  );
};

export default SearchBarAPI;
