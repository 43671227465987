import axios from 'axios';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { baseUrl } from '../../helpers/EncryptionHelper';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import CustomTableUser from './CustomTableEnglishWord';

export default function EnglishWordList() {
  function handleRemoveUser(id) {
    axios
      .delete(baseUrl + '/assets/remove/' + id)
      .then((res) => {
        toastr.success('Document Added Successfully', 'Success');
        // handleRefreshPage();
      })
      .catch((error) => {
        toastr.error('Document Upload Failed', 'Error!');
      });
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Dictionary'
            breadcrumbItem='Manage English Word'
          />

          <Row>
            <Col className='col-12'>
              <CustomTableUser />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
