import SearchBar from 'material-ui-search-bar';
import React from 'react';

export default function CustomSearchBar({ rows, setRows, keysToSearch }) {
  const [searched, setSearched] = React.useState('');
  const [originalRows, setOriginalRows] = React.useState([]);

  function requestSearch(searchedVal) {
    if (searchedVal?.length === 0) {
      return setRows(originalRows);
    }
    const filteredRows = originalRows.filter((row) => {
      let key = handleSearchKeys(keysToSearch, searchedVal, row);
      return key;
    });
    setRows(filteredRows);
  }
  function removeAccents(str) {
    return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  function handleSearchKeys(keysToSearch, searchedVal, row) {
    let filteredRowPresent = keysToSearch.filter((key) => {
      return removeAccents(row[key])
        ?.toLowerCase()
        .includes(removeAccents(searchedVal).toLowerCase());
    });

    return filteredRowPresent?.length > 0;
  }

  function cancelSearch() {
    setSearched('');
    setRows(originalRows);
  }

  React.useEffect(() => {
    if (originalRows?.length === 0) {
      setOriginalRows(rows);
    }
  }, [rows]);

  return (
    <SearchBar
      id='searchUsers'
      value={searched}
      onChange={(searchVal) => requestSearch(searchVal)}
      onCancelSearch={() => cancelSearch()}
      style={{
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15)',
      }}
    />
  );
}
