import { Dialog } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Input, Label } from 'reactstrap';

import { baseUrl } from '../../helpers/EncryptionHelper';
import useEnterKeyListener from '../../helpers/useEnterKeyListener';
import { Box, FormControl, InputLabel, MenuItem, Select, TextareaAutosize } from '@mui/material';
const GameLevelDialog = ({
  data,
  visible,
  handleClose,
  Transition,
  editGameLevel,
}) => {
  const [name, setName] = useState('');
  const [numberOfLives, setNumberOfLives] = React.useState('');
  const [timeLimit, setTimeLimit] = React.useState('');



  function handleValidation() {
    let errorText;
    if (!name) {
      errorText = 'Please enter Name';
    }
    if (errorText) {
      toastr.error(errorText, 'Error!');
    } else {
      handleAddEditWordType(editGameLevel);
    }
  }

  function handleAddEditWordType(isEdit) {
    let endpoint = isEdit
      ? `/game-level/edit/${data?.game_level_id      }`
      : '/game-level/add-game-level';
    let axiosMethod = isEdit ? axios.put : axios.post;
    axiosMethod(baseUrl + endpoint, {
      level_name: name,
      time_limit: timeLimit,
    
    })
      .then((res) => {
        toastr.success(
          `Game Level ${isEdit ? 'Edited' : 'Added'} Successfully`,
          'Success'
        );
        window.location.reload(false);
      })
      .catch((error) => {
        toastr.error(
          `Game level ${isEdit ? 'Edit' : 'Add'} Failed`,
          'Error!'
        );
      });
  }
  

  useEnterKeyListener({
    querySelectorToExecuteClick: '#submitDialectType',
  });

  useEffect(() => {
    if (data && editGameLevel) {
      setName(data?.level_name || '');
      setTimeLimit(data?.time_limit || '');
      
    } else {
      setName('');
      setTimeLimit('');
    }
  }, [data, editGameLevel]);
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{marginTop:'5%'}}
    >
    <div style={{ width: 400, padding: 40 }}>
  <CloseIcon
    style={{
      position: 'absolute',
      top: 20,
      right: 20,
      cursor: 'pointer',
    }}
    onClick={handleClose}
  />
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Center horizontally
      justifyContent: 'center', // Center vertically
    }}
  >
    <div style={{ marginBottom: '10px', width: '100%' }}>
      <Label className='form-label' htmlFor='addDialectTypeInput'>
      Name
      </Label>
      <Input
        type='text'
        className='form-control'
        id='Game-Level_Name'
        value={name}
        disabled={data && !editGameLevel}
        onChange={(e) => setName(e.target.value)}
        onFocus={() => (window.focusedInput = 'addDialectTypeInput')}
      />
    </div>
    <div style={{ marginBottom: '10px', width: '100%' }}>
      <Label className='form-label' htmlFor='demo-simple-select'>
      Number Of lives
            </Label>
      <Input
        type='number'
        className='form-control'
        id='Number-Of-Lives'
        value={numberOfLives}
        disabled={data && !editGameLevel}
        onChange={(e) => setNumberOfLives(e.target.value)}
        onFocus={() => (window.focusedInput = 'addDialectTypeInput')}
      />
    </div>
    <div style={{ marginBottom: '10px', width: '100%' }}>
      <Label className='form-label' htmlFor='formrow-firstname-input'>
      Time Limit (in seconds)
      </Label>
      <Input
        type='number'
        className='form-control'
        id='Time-Limit'
        value={timeLimit}
        onChange={(e) => setTimeLimit(e.target.value)}
      />
    </div>
  </div>

        {!(data && !editGameLevel) && (
          <div className='mt-4'>
            <Button
              id='submitDialectType'
              color='primary'
              variant='contained'
              className='w-md'
              onClick={() => {
                handleValidation();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default GameLevelDialog;
