import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import CustomTableUser from './CustomTableYorubaWord';

export default function YorubaWordList() {
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Dictionary' breadcrumbItem='Manage Yoruba Word' />
          <Row>
            <Col className='col-12'>
              <CustomTableUser />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
