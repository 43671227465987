import Crypto from 'crypto';

const ENCRYPTION_KEY = 'NuX4UQ9ZjMiOWLewavCpt2PyP6ojWuLv'; // Must be 256 bits (32 characters)
// const ENCRYPTION_KEY = 'AAECAwQFBgcICQoLDA0ODw=='; // Must be 256 bits (32 characters)
const IV_LENGTH = 16;

function encrypt(text) {
  let iv = Crypto.randomBytes(IV_LENGTH);
  let cipher = Crypto.createCipheriv(
    'aes-256-cbc',
    Buffer.from(ENCRYPTION_KEY),
    iv
  );

  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return iv.toString('hex') + ':' + encrypted.toString('hex');
}

export default encrypt;

//export const baseUrl ='http://localhost:8800/api/v1';
 export const baseUrl = 'https://backend.theaflangproject.org/api/v1';

export const toHtmlEntities = (str, showInHtml = false) =>
  [...str]
    .map((v) => `${showInHtml ? `&amp;#` : `&#`}${v.charCodeAt(0)};`)
    .join(``);
