import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import EnhancedTableHead, {
  Transition,
  getComparator,
  stableSort,
} from "../../components/Common/EnhancedTableHead";

import { Button, Slide } from "@material-ui/core";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import QuizDialog from "./quizDialog";
import DeleteDailog from "../../components/Common/DeleteDailog";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { baseUrl } from "../../helpers/EncryptionHelper";
import LoadIndicator from "../../components/Common/LoadIndicator";
import CustomSearchBar from "../../components/Common/CustomSearchBar";

const headCells = [
  {
    id: "game_name",
    numeric: false,
    disablePadding: false,
    label: "Game",
  },
  {
    id: "level",
    numeric: false,
    disablePadding: false,
    label: "Level",
  },
  {
    id: "question",
    numeric: false,
    disablePadding: false,
    label: "Questions",
  },
  {
    id: "game_type",
    numeric: false,
    disablePadding: false,
    label: "Type/Mode",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

// process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_BACKEND_ROUTE;

export default function CustomQuizType() {
  const history = useHistory();
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("game_name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openDailog, setOpenDailog] = useState(false);
  const [openDeleteDailog, setOpenDeleteDailog] = useState(false);
  const [editQuiz, setEditQuiz] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(false);

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [showLoader, setShowLoader] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = () => {
    setEditQuiz(false);
    setSelectedQuiz();
    setOpenDailog(false);
  };

  function handleCloseDeleteDailog() {
    setSelectedQuiz();
    setOpenDeleteDailog(false);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setOffset((s) => s + limit)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const handleAddNewQuiz = () => {
    setSelectedQuiz(null);
    setOpenDailog(true);
    // Navigate to the Quiz Form path
    history.push("/Quiz-Form");
  };

  const handleEdit = (row) => {
    setSelectedQuiz(row);
    setOpenDailog(true);
    // Navigate to the Quiz Form path
    
    history.push({
      pathname: '/Quiz-Form',
      state: {
        // isViewOnly: true,
        gameData: row,
      },
    });
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDelete = async (data) => {
    // console.log("data  ",data);

    if (data.game_master_id && data.level) {
      axios
        .delete(baseUrl + `/quiz/remove/${data.game_master_id}/${data.level}/${data.game_type}`)
        .then((res) => {
          if (res && res.data.status === 200) {
            handleCloseDeleteDailog();
            toastr.success("game-master Deleted Successfully", "Success");

            fetchData(0, limit);
            setOffset(0);
          }
        })
        .catch((err) => {
          toastr.error(`game-master removal Failed`, "Error!");
        });
    } else {
      console.error("Unable to determine the subscription ID for deletion");
    }
  };

  const handleCloseAndRefresh = () => {
    setEditQuiz(false); // Add this line
    setOpenDailog(false);
    setSelected([]);
    setTimeout(() => {
      fetchData(0, limit);
      setOffset(0);
    }, 200);
  };

  const fetchData = async (offset, limit) => {
    axios
      .get(baseUrl + `/quiz/all?limt=${limit}&offset=${offset}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          setRows(res.data.data.list);
          setTotalCount(parseInt(res.data.data?.list?.length));
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log("error");
        setShowLoader(false);
      });
  };

  useEffect(() => {
    fetchData(offset, limit);
  }, [limit, offset]);

  const ITEMS_PER_PAGE = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const currentData = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page*rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage, rows])
  
  return (
    <div className={classes.root}>
      <Button
        style={{
          backgroundColor: "#257b59",
          color: "white",
          padding: 10,
          alignSelf: "flex-end",
          marginBottom: 20,
        }}
        onClick={handleAddNewQuiz}
      >
        Add New Quiz
      </Button>
      <Paper className={classes.paper}>
        {showLoader ? (
          <LoadIndicator />
        ) : (
          <>
            <CustomSearchBar
              rows={rows}
              setRows={setRows}
              keysToSearch={["game_name"]}
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {currentData.map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell align="left">{row.game_name}</TableCell>
                        <TableCell align="left">{row.level}</TableCell>
                        <TableCell align="left">{row.question}</TableCell>
                        <TableCell align="left">{row.game_type|| 'NA'}</TableCell>

                        <TableCell width={"30%"} align="center">
                          {/* <IconButton
                            variant="contained"
                            color="#ddd"
                            onClick={() => {
                              setEditQuiz(true);
                              setSelectedQuiz(row);
                              setOpenDailog(true);
                            }}
                          >
                            <Visibility />
                          </IconButton> */}
                          <IconButton
                            variant="contained"
                            color="#ddd"
                            onClick={() => handleEdit(row)}
                          >
                            <Edit />
                          </IconButton>

                          <IconButton
                            variant="contained"
                            color="#ddd"
                            onClick={() => {
                              setOpenDeleteDailog(true);
                              setSelectedQuiz(row);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component='div'
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handleChange}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </div>
          </>
        )}
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />

      {openDailog && (
        <QuizDialog
          data={selectedQuiz}
          visible={openDailog}
          handleClose={handleClose}
          Transition={Transition}
          editQuiz={editQuiz}
        />
      )}
      {openDeleteDailog && (
        <DeleteDailog
          data={selectedQuiz}
          visible={openDeleteDailog}
          handleClose={handleCloseDeleteDailog}
          Transition={Transition}
          dialogTitle={() => (
            <div className="mt-3">
              Do you want to delete this Question :{" "}
              <strong>{selectedQuiz?.question || "NA"}</strong>
            </div>
          )}
          handleDeleteClicked={() => handleDelete(selectedQuiz)}
        />
      )}
    </div>
  );
}
