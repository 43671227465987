import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { baseUrl } from '../../helpers/EncryptionHelper';
import axios from 'axios';
import RichTextEditor from 'react-rte';
import { Dialog, makeStyles } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Transition } from '../../components/Common/EnhancedTableHead';
import { IconButton, TextField } from '@mui/material';
import { Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import LoadIndicator from '../../components/Common/LoadIndicator';
import { Add } from '@material-ui/icons';
import useEnterKeyListener from '../../helpers/useEnterKeyListener';
import DeleteDailog from '../../components/Common/DeleteDailog';

export default function AddMember(props) {
  const defaultRowValue = {
    synonym: [],
    antonym: [],
    hyponym: [],
    hypernym: [],
    keyword: [],
    wordType: '',
    definition: RichTextEditor.createEmptyValue(),
    example: RichTextEditor.createEmptyValue(),
    dialectRow: [
      {
        dialect: '',
        dialectType: null,
      },
    ],
  };
  const { isViewOnly: isViewOnlyProp, rowData, fromEdit } =
    props.history?.location?.state || {};
  const classes = useStyles();
  const history = useHistory();

  const [isViewOnly, setIsViewOnly] = useState(isViewOnlyProp);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialectType, setDialectType] = useState([]);
  const [phonemic, setPhonemic] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [audioName, setAudioName] = useState('');
  const [isAudioNull, setIsAudioNull] = useState(false);
  const [audioBlob, setAudioBlob] = useState('');
  const [audioChanged, setAudioChanged] = useState(false);
  const [historyEntymology, setHistoryEntymology] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [wordType, setWordType] = useState([]);
  const [wordRow, setWordRow] = useState([defaultRowValue]);
  const [initialFetchedData, setInitialFetchedData] = useState();
  const [yorubaWordList, setYorubaWordList] = useState([]);
  const [remainingYorubaWordList, setRemainingYorubaWordList] = useState([]);
  const [openAddWord, setOpenAddWord] = useState(false);
  const [selectedYorubaWord, setSelectedYorubaWord] = useState();
  const [modalOpenedFrom, setModalOpenedFrom] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [prevWord, setPrevWord] = useState(null);
  const [nextWord, setNextWord] = useState(null);

  const [isRTEBlurred, setIsRTEBlurred] = useState(true);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option?.label,
    ignoreAccents: true,
    ignoreCase: true,
  });

  const customControl = (index, labelName) => [
    () => (
      <button
        style={{
          border: '1px solid #999999',
          height: 30,
          textAlign: 'center',
          lineHeight: 0,
          borderRadius: 2,
          color: '#495057',
        }}
        type='button'
        onClick={() => {
          setModalOpenedFrom({ index, labelName });
          setOpenAddWord(true);
        }}
      >
        Add Word
      </button>
    ),
  ];

  const fetchWordType = async () => {
    axios
      .get(baseUrl + `/word-type/all`)
      .then((res) => {
        if (res && res.data.status === 200) {
          const options = res.data.data.list.map((d) => ({
            value: d.word_type_id,
            label: d.word_type_name,
          }));
          setWordType(options);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const fetchDialect = async (offset, limit) => {
    axios
      .get(baseUrl + `/dialect-type/all`)
      .then((res) => {
        if (res && res.data.status === 200) {
          setDialectType(res.data.data.list);
          const options = res.data.data.list.map((d) => ({
            value: d.dialect_type_id,
            label: d.dialect_type_name,
          }));
          setDialectType(options);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const fetchRemainingYorubaWords = async () => {
    axios
      .get(baseUrl + `/yoruba-word/remaining-words`)
      .then((res) => {
        if (res && res.data.status === 200) {
          const options = res.data.data.list.map((d) => ({
            value: d?.yoruba_word_id,
            label: d?.yoruba_word_name,
          }));
          setRemainingYorubaWordList(options);
        }
      })
      .catch((err) => {
        console.log('error');
      });
  };
  const fetchYorubaWords = async () => {
    axios
      .get(baseUrl + `/yoruba-word/all`)
      .then((res) => {
        if (res && res.data.status === 200) {
          const options = res.data.data.list.map((d) => ({
            value: d?.yoruba_word_id,
            label: d?.yoruba_word_name,
          }));
          setYorubaWordList(options);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  function handleChangeState(key, value, index) {
    setWordRow((wordRow) => {
      let newWordRow = [...wordRow];
      newWordRow[index][key] = value;
      return newWordRow;
    });
  }

  function handleEditorState(id, label) {
    const link = baseUrl + '/yoruba-word/get/' + id;
    const rteValue = `<span> </span><a href=${encodeURI(
      link
    )}>${label}</a><span>&nbsp;</span>`;
    let newEditorState;
    if (modalOpenedFrom?.labelName) {
      newEditorState = wordRow[modalOpenedFrom.index][modalOpenedFrom.labelName]
        .toString('html')
        .split('</p>')[0];
      newEditorState = newEditorState + rteValue + ' </p>';
      handleChangeState(
        modalOpenedFrom.labelName,
        wordRow[modalOpenedFrom.index][
          modalOpenedFrom.labelName
        ].setContentFromString(newEditorState, 'html'),
        modalOpenedFrom.index
      );
    } else {
      newEditorState = historyEntymology.toString('html').split('</p>')[0];
      newEditorState = newEditorState + rteValue + ' </p>';
      setHistoryEntymology((history) =>
        history.setContentFromString(newEditorState, 'html')
      );
    }
  }

  function getRequestBody(reqBody) {
    let newReqBody = {};
    Object.keys(reqBody).forEach((key) => {
      if (
        JSON.stringify(reqBody[key]) !== JSON.stringify(initialFetchedData[key])
      ) {
        newReqBody = { ...newReqBody, [key]: reqBody[key] };
      }
    });
    return newReqBody;
  }

  function handleFileSelect(event) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === 'audio/mp3' ||
        selectedFile.type === 'audio/mpeg' ||
        selectedFile.type === 'audio/wav'
      ) {
        setAudioBlob(selectedFile);
        setAudioChanged(true)
      } else {
        console.error('Invalid file type. Please select an MP3 or WAV file.');
      }
    }
  }

  function handleValidation() {
    let errorMessage;
    if (!selectedYorubaWord) {
      errorMessage = 'Please select a word';
    } else if (!wordRow?.[0]?.wordType) {
      errorMessage = 'Please select a Word Type';
    } else if (wordRow?.[0]?.definition.toString('html') == '<p><br></p>') {
      errorMessage = 'Please enter definition';
    }
    if (errorMessage) {
      toastr.error(errorMessage, 'Error!');
      return true;
    }
    return false;
  }

  function handleUpload() {
    if (audioBlob && audioChanged) {
      const formData = new FormData();
      formData.append('file', audioBlob);
      axios
        .post(baseUrl + '/yoruba/upload/' + selectedYorubaWord?.value, formData)
        .then((res) => {
          toastr.success('Dictionary Word Added Successfully', 'Success');
        })
        .catch((error) => {
          toastr.error('Dictionary Word Add Failed', 'Error!');
        });
      setAudioChanged(false);
    }
  }
  
  function handleAudioDelete() {
    axios
      .post(baseUrl + '/yoruba/delete_audio/' + selectedYorubaWord?.value)
      .then((res) => {
        toastr.success('Audio deleted successfully', 'Success');
        setAudioUrl('');
        setIsAudioNull(true);
      })
      .catch((error) => {
        toastr.error('Error deleting audio', 'Error!');
        console.error('Error deleting audio:', error);
      });
  }

  function handleCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  const handleDelete = async (id) => {
    axios
      .delete(baseUrl + `/yoruba-word/remove/${id}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          handleCloseDeleteDialog();
          toastr.success('Yoruba Word Deleted Successfully', 'Success');
          history.push('/dictionary-word');
        }
      })
      .catch((err) => {
        toastr.error(`Yoruba Word removal Failed`, 'Error!');
      });
  };

  function handleAddDictionaryWord() {
    if (handleValidation()) return;
    handleUpload();
    const modifiedWordRow = wordRow.map((row) => ({
      ...row,
      definition: row.definition.toString('html'),
      example: row?.example?.toString('html'),
      antonym: row?.antonym?.map((data) => data.value),
      hypernym: row?.hypernym?.map((data) => data.value),
      hyponym: row?.hyponym?.map((data) => data.value),
      keyword: row?.keyword?.map((data) => data.value),
      synonym: row?.synonym?.map((data) => data.value),
      wordType: row?.wordType?.value,
      dialectRow: (row?.dialectRow
        ?.filter((dialect) => 
          dialect.dialect.trim() !== '' && 
          dialect.dialectType !== null && 
          dialect.dialectType !== 'DEFAULT' // Filter dialects where dialect is not empty and dialectType is not null and dialectType is not default
        )
        .map((dialect) => ({
          dialect: dialect.dialect,
          dialectType: dialect.dialectType
        }))) || [] // Set to empty array if undefined
    }));
    let reqBody = {
      yoruba_word_id: selectedYorubaWord?.value,
      yoruba_word_name: selectedYorubaWord?.label,
      phonemic_transcription: phonemic,
      history_entymology: historyEntymology.toString('html'),
      wordRow: modifiedWordRow,
    };
    reqBody = rowData ? getRequestBody(reqBody) : reqBody;
    axios
      .put(baseUrl + '/yoruba-word/edit/' + selectedYorubaWord?.value, reqBody)
      .then((res) => {
        toastr.success('Dictionary Word Added Successfully', 'Success');
        setShowLoader(true);
        setIsViewOnly(true);
        history.push({
          pathname: '/add-yoruba-word',
          state: {
            isViewOnly: true,
            rowData: {
              yoruba_word_id: selectedYorubaWord.value,
              yoruba_word_name: selectedYorubaWord.label,
            },
          },
        });
      })
      .catch((error) => {
        toastr.error('Dictionary Word Add Failed', 'Error!');
        console.log('error', error);
      });
  }

  function getYorubaWordById(id) {
    axios
      .get(baseUrl + `/yoruba-word/get/${id}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          const data = res.data.data;
          setSelectedYorubaWord({
            value: res.data?.data?.yoruba_word_id,
            label: res.data?.data?.yoruba_word_name,
          });
          setPhonemic(data.phonemic_transcription);

          if (data.audio_path) {
            let audio_filename = 'None';
            if (data.audio_path !== null) {
              audio_filename = data.audio_path.split('/').pop();
            }
            setAudioName(audio_filename);

            if (data.has_audio_file) {
              setAudioUrl(data.audio_path);
              setIsAudioNull(false);
            } 
            else {
              console.log('Audio file not accessible:', data.audio_path);
              setAudioUrl('');
              setIsAudioNull(true);
            }
          }
          else if (data.audio_url) {
            const audioBlob = new Blob([new Int8Array(data.audio_url?.data)], {
              type: 'audio/mp3',
            });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            setIsAudioNull(false);
          }
          else {
            setAudioUrl('');
            setIsAudioNull(true);
          }
          
          setHistoryEntymology((history) =>
            history.setContentFromString(data.history_entymology, 'html')
          );
          let fetchedWordRow = data?.wordRow?.map((row) => {
            return {
              ...row,
              id: row.id,
              definition: row.definition
                ? defaultRowValue.definition.setContentFromString(
                    row.definition,
                    'html'
                  )
                : RichTextEditor.createEmptyValue(),
              example: row.example
                ? defaultRowValue.example.setContentFromString(
                    row.example,
                    'html'
                  )
                : RichTextEditor.createEmptyValue(),
              antonym: row.antonym.map((word) => {
                return { value: word?.id, label: word?.yoruba_word_name };
              }),
              hypernym: row.hypernym.map((word) => {
                return { value: word?.id, label: word?.yoruba_word_name };
              }),
              hyponym: row.hyponym.map((word) => {
                return { value: word?.id, label: word?.yoruba_word_name };
              }),
              keyword: row.keyword.map((word) => {
                return { value: word?.id, label: word?.yoruba_word_name };
              }),
              synonym: row.synonym.map((word) => {
                return { value: word?.id, label: word?.yoruba_word_name };
              }),
              wordType: wordType?.find((word) => word.value == row.wordType),
              dialectRow: (row.dialectRow && row.dialectRow.length > 0 ? row.dialectRow.map((dialect) => ({
                dialect: dialect.dialect,
                dialectType: dialect.dialectType
              })) : defaultRowValue.dialectRow)
            };
          });

          setWordRow(fetchedWordRow);
          setInitialFetchedData(res.data.data);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log('error', err);
        setShowLoader(false);
      });
  }

  function setPrevAndNextWords(currentWordId) {
    if (currentWordId) {
      // Assuming yorubaWordList has been populated
      const currentIndex = yorubaWordList.findIndex(option => option.value === currentWordId);
  
      // Get the previous and next words
      const prevWord = currentIndex > 0 ? yorubaWordList[currentIndex - 1] : null;
      const nextWord = currentIndex < yorubaWordList.length - 1 ? yorubaWordList[currentIndex + 1] : null;
 
      setPrevWord(prevWord);
      setNextWord(nextWord);

      console.log("prevWord", prevWord);
      console.log("nextWord", nextWord);
    }
  }

  function renderOptions(props, option) {
    return (
      <li
        {...props}
        key={option.value + option?.label}
        style={{
          color: 'black',
          height: 40,
          textDecorationColor: 'black',
        }}
      >
        {option.label}
      </li>
    );
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: isRTEBlurred
      ? '#submitAddDictionaryWord'
      : null,
    deps: [isRTEBlurred],
  });

  useEffect(() => {
    fetchRemainingYorubaWords();
    fetchYorubaWords();
    fetchWordType();
    fetchDialect();
  }, []);

  useEffect(() => {
    if (rowData && wordType?.length > 0 && dialectType?.length > 0) {
      getYorubaWordById(rowData?.yoruba_word_id);
    }
  }, [rowData, wordType, dialectType]);

  useEffect(() => {
    if (yorubaWordList.length > 0 && rowData) {
      setPrevAndNextWords(rowData.yoruba_word_id);
    }
  }, [yorubaWordList, rowData]);

  return (
    <React.Fragment>
      <div
        className='page-content'
        style={{
          paddingBottom: !fromEdit ? 100 : 0,
          padding: fromEdit ? 0 : null,
          margin: fromEdit ? 0 : null,
          maxWidth: fromEdit ? '100%' : null,
        }}
      >
        {showLoader && rowData ? (
          <LoadIndicator />
        ) : (
          <Container
            fluid
            style={{
              maxWidth: fromEdit ? '100%' : null,
            }}
          >
            {!fromEdit && (
              <Breadcrumbs
                title='Dictionary'
                breadcrumbItem={
                  isViewOnly ? 'View Yoruba Word' : 'Add Yoruba Word'
                }
              />
            )}
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <div className='wizard clearfix'>
                      <div className='body'>
                        <Row>
                          <Col lg='3'>
                            <div className='mb-3'>
                              <Label
                                className='required-field'
                                for='basicpill-firstname-input1'
                              >
                                Word
                              </Label>
                              <Autocomplete
                                id='size-small-outlined-multi'
                                size='small'
                                options={remainingYorubaWordList}
                                getOptionLabel={(option) => option?.label || ''}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(_, newValue) => {
                                  setSelectedYorubaWord(newValue);
                                }}
                                value={selectedYorubaWord}
                                disabled={isViewOnly}
                                filterOptions={filterOptions}
                                renderOption={(props, option) =>
                                  renderOptions(props, option)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg='3'>
                            <div className='mb-3'>
                              <Label for='basicpill-lastname-input2'>
                                Phonemic Transcription
                              </Label>
                              <Input
                                type='text'
                                className='form-control'
                                id='phonemic_transcription'
                                value={phonemic}
                                onChange={(e) => {
                                  setPhonemic(e.target.value);
                                }}
                                disabled={isViewOnly}
                                onFocus={() =>
                                  (window.focusedInput =
                                    'phonemic_transcription')
                                }
                              />
                            </div>
                          </Col>
                          <Col lg='3'>
                            <div className='mb-3'>
                              <Label for='basicpill-lastname-input2'>
                                Audio Filename
                              </Label>
                              <Input
                                type='text'
                                className='form-control'
                                id='audio_name'
                                value={audioName}
                                disabled={true}
                                onFocus={() =>
                                  (window.focusedInput =
                                    'audio_name')
                                }
                              />
                            </div>
                          </Col>
                          <Col lg='3'>
                            <div className='mb-3'>
                              <Label for='basicpill-lastname-input2'>
                                Audio File
                              </Label>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                              {isViewOnly && (
                                  <audio controls disabled={isAudioNull}>
                                  <source src={audioUrl} type='audio/mp3' />
                                    Your browser does not support the audio element.
                                </audio>
                              )}
                              {!isViewOnly && (
                                <Input
                                  type='file'
                                  accept='.mp3, .wav .mpeg'
                                  className='form-control'
                                  id='basicpill-lastname-input2'
                                  // value={audioUrl?.name || ''}
                                  onChange={(e) => {
                                    handleFileSelect(e);
                                  }}
                                  disabled={true}
                                />
                              )}
                                <IconButton
                                  variant='contained'
                                  color='warning'
                                  onDoubleClick={() => {
                                    handleAudioDelete();
                                  }}
                                  disabled={isAudioNull}
                                >
                                  <Delete />
                                </IconButton>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg='12'>
                            <div className='mb-3'>
                              <Label for='basicpill-address-input1'>
                                History
                              </Label>
                              <RichTextEditor
                                editorClassName='editor'
                                value={historyEntymology}
                                onChange={setHistoryEntymology}
                                customControls={customControl()}
                                disabled={isViewOnly}
                                onBlur={(event) => setIsRTEBlurred(true)}
                                onFocus={(event) => setIsRTEBlurred(false)}
                              />
                            </div>
                          </Col>
                        </Row>
                        {wordRow.sort((a, b) => a.id - b.id).map((row, i) => (
                          <>
                            <div
                              style={{
                                width: '100%',
                                height: 4,
                                backgroundColor: '#eaeaea',
                                marginTop: 24,
                                marginBottom: 24,
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                flexDirection: 'row',
                                width: '100%',
                              }}
                            >
                              <CloseIcon
                                style={{
                                  top: 20,
                                  right: 20,
                                  cursor: 'pointer',
                                  alignSelf: 'end',
                                }}
                                onClick={() => {
                                  setWordRow((wordRow) => {
                                    let row = wordRow;
                                    if (wordRow.length > 1)
                                      row = row?.filter(
                                        (item, index) => index !== i
                                      );
                                    return row;
                                  });
                                }}
                              />
                            </div>
                            <Row>
                              <Col lg='4'>
                                <div className='mb-3'>
                                  <Label for='basicpill-lastname-input2'>
                                    Synonym
                                  </Label>
                                  <Autocomplete
                                    multiple
                                    id='size-small-outlined-multi'
                                    size='small'
                                    options={yorubaWordList}
                                    getOptionLabel={(option) => option?.label}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(_, newValue) => {
                                      let newWordRow = [...wordRow];
                                      newWordRow[i].synonym = newValue;
                                      setWordRow(newWordRow);
                                    }}
                                    value={row.synonym}
                                    disabled={isViewOnly}
                                    filterOptions={filterOptions}
                                    renderOption={(props, option) =>
                                      renderOptions(props, option)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg='4'>
                                <div className='mb-3'>
                                  <Label for='basicpill-lastname-input2'>
                                    Antonym
                                  </Label>
                                  <Autocomplete
                                    multiple
                                    id='size-small-outlined-multi'
                                    size='small'
                                    options={yorubaWordList}
                                    getOptionLabel={(option) => option?.label}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(_, newValue) => {
                                      let newWordRow = [...wordRow];
                                      newWordRow[i].antonym = newValue;
                                      setWordRow(newWordRow);
                                    }}
                                    value={row.antonym}
                                    disabled={isViewOnly}
                                    filterOptions={filterOptions}
                                    renderOption={(props, option) =>
                                      renderOptions(props, option)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg='4'>
                                <div className='mb-3'>
                                  <Label for='basicpill-lastname-input2'>
                                    Hyponym
                                  </Label>
                                  <Autocomplete
                                    multiple
                                    id='size-small-outlined-multi'
                                    size='small'
                                    options={yorubaWordList}
                                    getOptionLabel={(option) => option?.label}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(_, newValue) => {
                                      let newWordRow = [...wordRow];
                                      newWordRow[i].hyponym = newValue;
                                      setWordRow(newWordRow);
                                    }}
                                    value={row.hyponym}
                                    disabled={isViewOnly}
                                    filterOptions={filterOptions}
                                    renderOption={(props, option) =>
                                      renderOptions(props, option)
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg='4'>
                                <div className='mb-3'>
                                  <Label for='basicpill-lastname-input2'>
                                    Hypernym
                                  </Label>
                                  <Autocomplete
                                    multiple
                                    id='size-small-outlined-multi'
                                    size='small'
                                    options={yorubaWordList}
                                    getOptionLabel={(option) => option?.label}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(_, newValue) => {
                                      let newWordRow = [...wordRow];
                                      newWordRow[i].hypernym = newValue;
                                      setWordRow(newWordRow);
                                    }}
                                    value={row.hypernym}
                                    disabled={isViewOnly}
                                    filterOptions={filterOptions}
                                    renderOption={(props, option) =>
                                      renderOptions(props, option)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg='4'>
                                <div className='mb-3'>
                                  <Label for='basicpill-lastname-input2'>
                                    keyword
                                  </Label>
                                  <Autocomplete
                                    multiple
                                    id='size-small-outlined-multi'
                                    size='small'
                                    options={yorubaWordList}
                                    getOptionLabel={(option) => option?.label}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(_, newValue) => {
                                      let newWordRow = [...wordRow];
                                      newWordRow[i].keyword = newValue;
                                      setWordRow(newWordRow);
                                    }}
                                    value={row.keyword}
                                    disabled={isViewOnly}
                                    filterOptions={filterOptions}
                                    renderOption={(props, option) =>
                                      renderOptions(props, option)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg='4'>
                                <div className='mb-3'>
                                  <Label
                                    for='basicpill-lastname-input2'
                                    className='required-field'
                                  >
                                    Word Type
                                  </Label>
                                  <Autocomplete
                                    id='size-small-outlined-multi'
                                    size='small'
                                    options={wordType}
                                    getOptionLabel={(option) =>
                                      option?.label || ''
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(_, newValue) => {
                                      let newWordRow = [...wordRow];
                                      newWordRow[i].wordType = newValue;
                                      setWordRow(newWordRow);
                                    }}
                                    value={row.wordType}
                                    disabled={isViewOnly}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg='6'>
                                <div className='mb-3'>
                                  <Label
                                    for='basicpill-address-input1'
                                    className='required-field'
                                  >
                                    Definition
                                  </Label>
                                  <RichTextEditor
                                    value={row.definition}
                                    onChange={(x) => {
                                      let newWordRow = [...wordRow];
                                      newWordRow[i].definition = x;
                                      setWordRow(newWordRow);
                                    }}
                                    customControls={customControl(
                                      i,
                                      'definition'
                                    )}
                                    disabled={isViewOnly}
                                    onBlur={(event) => setIsRTEBlurred(true)}
                                    onFocus={(event) => setIsRTEBlurred(false)}
                                  />
                                </div>
                              </Col>
                              <Col lg='6'>
                                <div className='mb-3'>
                                  <Label for='basicpill-address-input1'>
                                    Example
                                  </Label>
                                  <RichTextEditor
                                    value={row.example}
                                    onChange={(x) => {
                                      let newWordRow = [...wordRow];
                                      newWordRow[i].example = x;
                                      setWordRow(newWordRow);
                                    }}
                                    customControls={customControl(i, 'example')}
                                    disabled={isViewOnly}
                                    onBlur={(event) => setIsRTEBlurred(true)}
                                    onFocus={(event) => setIsRTEBlurred(false)}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {row.dialectRow.map((data, ind) => (
                              <Row>
                                <Col lg='5'>
                                  <div className='mb-3'>
                                    <Label for='basicpill-firstname-input1'>
                                      Dialect
                                    </Label>
                                    <Input
                                      type='input'
                                      className='form-control'
                                      id='dialectTypeInput'
                                      value={data.dialect}
                                      onChange={(e) => {
                                        let newWordRow = [...wordRow];
                                        newWordRow[i].dialectRow[ind].dialect =
                                          e.target.value;
                                        setWordRow(newWordRow);
                                      }}
                                      disabled={isViewOnly}
                                      onFocus={() =>
                                        (window.focusedInput =
                                          'dialectTypeInput')
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg='5'>
                                  <div className='mb-3'>
                                    <Label for='basicpill-lastname-input2'>
                                      Dialect Type
                                    </Label>
                                    <Input
                                      type='select'
                                      className='form-control'
                                      id='basicpill-firstname-input1'
                                      value={data.dialectType}
                                      onChange={(e) => {
                                        let newWordRow = [...wordRow];
                                        newWordRow[i].dialectRow[
                                          ind
                                        ].dialectType = e.target.value;
                                        setWordRow(newWordRow);
                                      }}
                                      defaultValue={'DEFAULT'}
                                      disabled={isViewOnly}
                                    >
                                      <option value='DEFAULT'>
                                        Choose Dialect Type ...
                                      </option>
                                      {dialectType.map((word) => (
                                        <option value={word.value}>
                                          {word.label}
                                        </option>
                                      ))}
                                    </Input>
                                  </div>
                                </Col>
                                {!isViewOnly &&
                                  (ind === row.dialectRow.length - 1 ? (
                                    <Col
                                      style={{
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        display: 'flex',
                                      }}
                                    >
                                      <IconButton
                                        style={{
                                          borderRadius: 100,
                                          backgroundColor: '#e1a9a9',
                                          alignSelf: 'center',
                                        }}
                                        onClick={() => {
                                          let newWordRow = [...wordRow];
                                          newWordRow[i].dialectRow.push({
                                            dialect: '',
                                            dialectType: null,
                                          });
                                          setWordRow(newWordRow);
                                        }}
                                      >
                                        <Add />
                                      </IconButton>
                                    </Col>
                                  ) : (
                                    <Col
                                      style={{
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        display: 'flex',
                                      }}
                                    >
                                      <IconButton
                                        style={{
                                          borderRadius: 100,
                                          backgroundColor: '#eaeaea',
                                          alignSelf: 'center',
                                        }}
                                        onClick={() => {
                                          let newWordRow = [...wordRow];
                                          newWordRow[i].dialectRow.splice(
                                            ind,
                                            1
                                          );
                                          setWordRow(newWordRow);
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Col>
                                  ))}
                              </Row>
                            ))}
                          </>
                        ))}
                        {!isViewOnly && (
                          <div className='actions clearfix'>
                            <Link
                              to='#'
                              className='btn btn-secondary'
                              onClick={() => {
                                setWordRow((wordRow) => {
                                  let row = [...wordRow];
                                  row.push(defaultRowValue);
                                  return row;
                                });
                              }}
                              style={{
                                alignItems: 'center',
                                // display: 'flex',
                              }}
                            >
                              + Add More
                            </Link>
                          </div>
                        )}
                      </div>

                      {openAddWord && (
                        <AddWordModal
                          yorubaWordList={yorubaWordList}
                          setOpenAddWord={setOpenAddWord}
                          handleEditorState={handleEditorState}
                        />
                      )}
                      <Row>
                        <Col lg='2'>
                          <div className='mt-4'>
                            <Button
                              id='deleteDictionaryWord'
                              color='warning'
                              variant='contained'
                              className='w-md'
                              onClick={() => {
                                setOpenDeleteDialog(true);
                              }}
                            >
                              Delete Word
                            </Button>
                            {openDeleteDialog && (
                              <DeleteDailog
                                data={selectedYorubaWord}
                                visible={openDeleteDialog}
                                handleClose={handleCloseDeleteDialog}
                                Transition={Transition}
                                dialogTitle={() => (
                                  <div className='mt-3'>
                                    Do you want to delete this Word :{' '}
                                    <strong>{selectedYorubaWord?.label || 'NA'}</strong>
                                  </div>
                                )}
                                handleDeleteClicked={(data) => {
                                  if (data) {
                                    handleDelete(data.value);
                                  } else {
                                    alert('No data to delete');
                                  }
                                }}
                              />
                            )}
                          </div>
                        </Col>
                        <Col lg='2'>
                        {isViewOnly ? (
                          <div className='mt-4'>
                            <Button
                              id='editDictionaryWord'
                              color='primary'
                              variant='contained'
                              className='w-md'
                              onClick={() => {
                                setIsViewOnly(false);
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        ) : (
                            <div className='mt-4'>
                              <Button
                                id='submitAddDictionaryWord'
                                color='primary'
                                variant='contained'
                                className='w-md'
                                onClick={() => {
                                  handleAddDictionaryWord();
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          )}
                        </Col>

                        {(!isViewOnly && (
                          <Col lg='2'>
                            <div className='mt-4'>
                              <Button
                                // color='primary'
                                // variant='contained'
                                className='w-md'
                                onClick={() => {
                                  history.push('/dictionary-word');
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Col>
                        )) || (
                          <div className='mt-4'>
                            <Button
                              // color='primary'
                              // variant='contained'
                              className='w-md'
                              onClick={() => {
                                history.push('/dictionary-word');
                              }}
                            >
                              Back
                            </Button>
                          </div>
                        )}

                        <Col lg='2'>
                          <div className='mt-4'>
                            {prevWord && (
                              <Button
                                id='prevDictionaryWord'
                                color='info'
                                variant='contained'
                                className='w-md'
                                onClick={() => {
                                  setShowLoader(true);
                                  setIsViewOnly(true);
                                  history.push({
                                    pathname: '/add-yoruba-word',
                                    state: {
                                      isViewOnly: true,
                                      rowData: {
                                        yoruba_word_id: prevWord.value,
                                        yoruba_word_name: prevWord.label,
                                      },
                                    },
                                  });
                                }}
                              >
                                Prev
                              </Button>
                            )}
                          </div>
                        </Col>
                        <Col lg='2'>
                          <div className='mt-4'>
                            {nextWord && (
                              <Button
                                id='nextDictionaryWord'
                                color='info'
                                variant='contained'
                                className='w-md'
                                onClick={() => {
                                  setShowLoader(true);
                                  setIsViewOnly(true);
                                  history.push({
                                    pathname: '/add-yoruba-word',
                                    state: {
                                      isViewOnly: true,
                                      rowData: {
                                        yoruba_word_id: nextWord.value,
                                        yoruba_word_name: nextWord.label,
                                      },
                                    },
                                  });
                                }}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}

function AddWordModal({ setOpenAddWord, yorubaWordList, handleEditorState }) {
  const [selectedWordType, setSelectedWordType] = useState();

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option?.label,
    ignoreAccents: true,
    ignoreCase: true,
  });

  return (
    <Dialog
      open={true}
      onClose={() => setOpenAddWord(false)}
      TransitionComponent={Transition}
    >
      <div style={{ width: 400, padding: 40 }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
          onClick={() => setOpenAddWord(false)}
        />
        <div className='mb-3'>
          <Label className='form-label' htmlFor='formrow-firstname-input'>
            Yoruba Word
          </Label>

          <Autocomplete
            id='size-small-outlined-multi'
            size='small'
            options={yorubaWordList}
            getOptionLabel={(option) => option?.label || ''}
            renderInput={(params) => (
              <TextField placeholder='Choose a yoruba word' {...params} />
            )}
            onChange={(_, newValue) => {
              setSelectedWordType(newValue);
            }}
            value={selectedWordType}
            filterOptions={filterOptions}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  key={option.value + option?.label}
                  style={{
                    color: 'black',
                    height: 40,
                    textDecorationColor: 'black',
                  }}
                >
                  {option.label}
                </li>
              );
            }}
          />
        </div>
        <div className='mt-4'>
          <Button
            color='primary'
            variant='contained'
            className='w-md'
            onClick={() => {
              setOpenAddWord(false);
              handleEditorState(
                selectedWordType?.value,
                selectedWordType?.label
              );
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: 300,
  },
  inputControl: {
    width: '100%',
  },
}));
