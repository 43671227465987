import PropTypes from 'prop-types';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';

//i18n
import SidebarContent from './SidebarContent';
import logoLight from '../../assets/images/dictionary.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sideBarWrapper: {
    display: 'block',
    [theme.breakpoints.down(990)]: {
      display: 'none',
    },
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div
        className={`vertical-menu ${classes.sideBarWrapper}`}
        style={{ display: props?.openSideBarMobile && 'block' }}
      >
        <div className='navbar-brand-box'>
          <Link to='/' className='logo logo-dark'>
            <span className='logo'>
              <img src={logoLight} alt='' style={{ width: '100%' }} />
            </span>
          </Link>
        </div>
        <div data-simplebar className='h-100'>
          {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

export default withRouter(Sidebar);
