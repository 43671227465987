import { Dialog } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Input, Label } from "reactstrap";

import { baseUrl } from "../../helpers/EncryptionHelper";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize
} from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const WordOfTheDayDialog = ({
  data,
  visible,
  handleClose,
  Transition,
  editWordOfTheDay,
}) => {
  console.log("data----", data)
  const [name, setName] = useState("");
  const [created, setCreated] = React.useState("");
  const [remainingYorubaWordList, setRemainingYorubaWordList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  // const [selectedYorubaWord, setSelectedYorubaWord] = useState();

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option?.label,
    ignoreAccents: true,
    ignoreCase: true,
  }); 


  useEffect(() => {
   
    axios
      .get(baseUrl + `/yoruba-word/all-dictionary`)
      .then((res) => {
        if (res && res.data.status === 200) {
          const options = res.data.data.list.map((d) => ({
            value: d?.yoruba_word_id,
            label: d?.yoruba_word_name,
          }));
          setRemainingYorubaWordList(options);
        }
      })
      .catch((err) => {
        console.log('error');
      });
  }, []);

  function handleValidation() {
    let errorText;
    if (!name) {
      errorText = "Please enter WordType";
    }
    if (errorText) {
      toastr.error(errorText, "Error!");
    } else {
      handleAddEditWordType(editWordOfTheDay);
    }
  }

  function handleAddEditWordType(isEdit) {
    let endpoint = isEdit
      ? `/wordofday/edit/${data?.yoruba_wordofday_id}`
      : "/wordofday/add-wordofday";
    let axiosMethod = isEdit ? axios.put : axios.post;
    axiosMethod(baseUrl + endpoint, {
      yoruba_word_id: name?.value,
      show_at: selectedDate?.toISOString(), // Convert date to ISO string
    })
      .then((res) => {
        toastr.success(
          `Word of the day ${isEdit ? "Edited" : "Added"} Successfully`,
          "Success"
        );
        window.location.reload(false);
      })
      .catch((error) => {
        toastr.error(
          `Word of the day ${isEdit ? "Edit" : "Add"} Failed`,
          "Error!"
        );
      });
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitDialectType",
  });

  function renderOptions(props, option) {
    return (
      <li
        {...props}
        key={option.value + option?.label}
        style={{
          color: 'black',
          height: 40,
          textDecorationColor: 'black',
        }}
      >
        {option.label}
      </li>
    );
  }
  useEffect(() => {
    if (data && editWordOfTheDay) {
      setName({
        value: data?.yoruba_word_id,
        label: data?.yoruba_word_name,
      });     
      // setSelectedDate(new Date(data?.show_at));
    } else {
      setName("");
      // setSelectedDate("");
    }
  }, [data, editWordOfTheDay]);
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{ marginTop: "5%" }}
    >
      <div style={{ width: 400, padding: 40, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
        <CloseIcon
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
          }}
          onClick={handleClose}
        />

        <div style={{ flexGrow: 1, marginLeft: '-50px' }}>
          <div>
            <Label className="form-label" htmlFor="addDialectTypeInput">
              Yoruba Word
            </Label>
            <Autocomplete
              id='size-small-outlined-multi'
              size='small'
              options={remainingYorubaWordList}
              getOptionLabel={(option) => option?.label || ''}
              renderInput={(params) => (
                <TextField {...params} />
              )}
              onChange={(_, newValue) => {
                setName(newValue);
              }}
              value={name}
              // disabled={isViewOnly}
              filterOptions={filterOptions}
              renderOption={(props, option) =>
                renderOptions(props, option)
              }
            />
          </div>

          <div style={{ marginTop: "5%" }}>
            <Label className="form-label" htmlFor="addDialectTypeInput">
              Date: {data?.show_at && `${new Date(
                                    data?.show_at
                                  ).getDate()}/${new Date(
                                    data?.show_at
                                  ).getMonth()}/${new Date(
                                    data?.show_at
                                  ).getFullYear()}`}
            </Label>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  sx={{ width: "120%", padding: "1px" }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        {!(data && !editWordOfTheDay) && (
          <div className="mt-4" style={{ flexShrink: 0 }}>
            <Button
              id="submitDialectType"
              color="primary"
              variant="contained"
              className="w-md"
              onClick={() => {
                handleValidation();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </Dialog>

  );
};

export default WordOfTheDayDialog;
