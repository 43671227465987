import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import 'toastr/build/toastr.min.css';
import CustomTableUser from './CustomTableUser';

export default function UserList() {
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Dictionary' breadcrumbItem='Manage Users' />
          <Row>
            <Col className='col-12'>
              <CustomTableUser />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
