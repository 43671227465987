import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardText, CardTitle, Col, Container, Row } from 'reactstrap';

export default function WelcomePage() {
  const [activeTab, setactiveTab] = useState(1);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
      }
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col className='text-center' style={{ color: '#9fa0a2' }}>
              <p className='mb-0 mt-4'>LOOKS LIKE YOU ARE NEW HERE</p>
              <h4 className='mb-0 mt-1'>
                Welcome to Dictionary. What would you like to do?
              </h4>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col md={4}>
              <Card body className='text-center'>
                <i
                  className='uil-map-pin'
                  style={{ fontSize: 50, color: '#257b59' }}
                ></i>
                <CardTitle className='h4 mt-2'>
                  Special title treatment
                </CardTitle>
                <CardText style={{ color: 'gray' }}>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Link
                  to='#'
                  className='btn btn-primary waves-effect waves-light'
                >
                  Go somewhere
                </Link>
              </Card>
            </Col>
            <Col md={4}>
              <Card body className='text-center'>
                <CardTitle className='h4 mt-0'>
                  Special title treatment
                </CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Link
                  to='#'
                  className='btn btn-primary waves-effect waves-light align-center'
                  style={{ width: 'fitContent' }}
                >
                  Go somewhere
                </Link>
              </Card>
            </Col>
            <Col md={4}>
              <Card body className='text-center'>
                <CardTitle className='h4 mt-0'>
                  Special title treatment
                </CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Link
                  to='#'
                  className='btn btn-primary waves-effect waves-light'
                >
                  Go somewhere
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
