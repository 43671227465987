import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { withRouter, Link, useHistory } from 'react-router-dom';

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const history = useHistory();

  let user = JSON.parse(sessionStorage.getItem('authUser'));

  function handleLogout() {
    sessionStorage.removeItem('authUser');
    history.push('/login');
  }

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setusername(obj.displayName)
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setusername(obj.username)
  //     }
  //   }
  // }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className='d-inline-block'
      >
        <DropdownToggle
          className='btn header-item waves-effect'
          id='page-header-user-dropdown'
          tag='button'
          style={{ alignItems: 'center', display: 'flex' }}
        >
          {user.img ? (
            <img
              className='rounded-circle header-profile-user'
              src={user.img}
              alt='mdi mdi-account-circle'
            />
          ) : (
            <>
              <i className='mdi mdi-account-circle display-6 m-0 text-primary' />
            </>
          )}
          <span className='d-none d-xl-inline-block ms-1 fw-medium font-size-15'>
            {user?.name || 'User'}
          </span>{' '}
          <i className='uil-angle-down d-none d-xl-inline-block font-size-15'></i>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <DropdownItem tag='a' href='/my-profile'>
            {' '}
            <i className='uil uil-user-circle font-size-18 align-middle text-muted me-1'></i>
            {'View Profile'}{' '}
          </DropdownItem>
          <div className='dropdown-divider' />
          <Link
            to='/login'
            className='dropdown-item'
            onClick={() => handleLogout()}
          >
            <i className='uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted'></i>
            <span>{'Logout'}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(ProfileMenu);
