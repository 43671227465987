import React, { useState } from 'react';
import SimpleKeyboard from 'react-simple-keyboard';
import { FaKeyboard, FaTimes } from 'react-icons/fa';
import 'react-simple-keyboard/build/css/index.css';
// import './StickyButton.css';

const StickyKeyboard = ({}) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  function getReactProps(el) {
    const keys = Object.keys(el);
    const propKey = keys.find((key) => key.includes('reactProps'));
    return el[propKey];
  }

  const changeInputValue = (button) => {
    if (window.focusedInput !== null) {
      const inputElement = document.getElementById(window.focusedInput);

      if (inputElement) {
        if (button === 'delete') {
          return getReactProps(inputElement)?.onChange({
            target: { value: inputElement.value.slice(0, -1) },
          });
        }
        getReactProps(inputElement)?.onChange({
          target: { value: inputElement.value + button },
        });
      }
    }
  };

  const toggleKeyboard = () => {
    setIsKeyboardOpen(!isKeyboardOpen);
  };

  const handleKeyPress = (button) => {
    if (button === '{keyboard}') {
      toggleKeyboard();
    } else if (button === '{space}') {
      changeInputValue(' ');
    } else if (button === '{delete}') {
      changeInputValue('delete');
    } else {
      changeInputValue(button);
    }
  };

  const layout = {
    default: [
      'à À á Á ā à᷉ á᷉ dʒ è é ẹ́ ẹ Ẹ ẹ̀ Ẹ̀ Ẹ́ ē ɛ́ ɛ̀ ɛ̃ ɡ͡b ì Ì',
      'ı̀᷉ í Í í᷉ ī i᷉ k͡p ḿ Ḿ m̀ M̀ m̄ n̄ ń Ń ǹ Ǹ ò Ò ó Ó ọ',
      'Ọ ɔ ọ́ Ọ́ ɔ́ ɔ́᷉ ọ̀ Ọ̀ ɔ̀ ɔ̀᷉ ɔ̄ ɔ̃ ṣ Ṣ ʃ u᷉ ú Ú ú᷉ ù Ù ù᷉ {keyboard} {delete}',
      '{space}',
    ],
  };

  return (
    <div style={{ zIndex: 100000000, left: 0 }}>
      <div
        style={{
          // backgroundColor: 'white',
          // borderRadius: 100,
          aspectRatio: 1,
          width: 70,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          // overflow: 'hidden',
        }}
        onClick={toggleKeyboard}
      >
        <i className={`keyboard-icon ${isKeyboardOpen ? 'close-icon' : ''}`}>
          {isKeyboardOpen ? <FaTimes size={30} /> : <FaKeyboard size={30} />}
        </i>
      </div>
      {isKeyboardOpen && (
        <div style={{ position: 'absolute', top: 80, right: 20 }}>
          <SimpleKeyboard
            layout={layout}
            onChange={(input) => {
              // Handle input change here
            }}
            onKeyPress={handleKeyPress}
            stopMouseDownPropagation
            stopMouseUpPropagation
          />
        </div>
      )}
    </div>
  );
};

export default StickyKeyboard;
