import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import { Button, IconButton ,InputLabel,Select,MenuItem} from '@material-ui/core';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../helpers/EncryptionHelper';
import EnhancedTableHead, {
  Transition,
  getComparator,
  stableSort,
} from '../../components/Common/EnhancedTableHead';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import DeleteDailog from '../../components/Common/DeleteDailog';
import LoadIndicator from '../../components/Common/LoadIndicator';
import CustomSearchBar from '../../components/Common/CustomSearchBar';
import SearchBar from '../../components/Common/Search';

const headCells = [
  {
    id: 'yoruba_word_name',
    numeric: false,
    disablePadding: false,
    label: 'Word',
  },
  {
    id: 'phonemic_transcription',
    numeric: false,
    disablePadding: false,
    label: 'Phonemic Transcription',
  },
  {
    id: 'audio_path',
    numeric: false,
    disablePadding: false,
    label: 'Audio File Name',
  },
  {
    id: 'audio_url',
    numeric: false,
    disablePadding: false,
    label: 'Audio File',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    align: 'center',
  },

  // { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function CustomTableYorubaWord() {
  const history = useHistory();

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rows, setRows] = useState([]);
  const [audioList, setAudioList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [openDeleteDailog, setOpenDeleteDailog] = useState(false);
  const [selectedYorubaWord, setselectedYorubaWord] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('starts');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setOffset((s) => s + limit)
  };
  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1); // Reset pagination when searching
  };

  function handleCloseDeleteDailog() {
    setselectedYorubaWord();
    setOpenDeleteDailog(false);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDelete = async (id) => {
    axios
      .delete(baseUrl + `/yoruba-word/remove/${id}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          handleCloseDeleteDailog();
          toastr.success('Yoruba Word Deleted Successfully', 'Success');

          fetchData(0, limit);
          setOffset(0);
        }
      })
      .catch((err) => {
        toastr.error(`Yoruba Word removal Failed`, 'Error!');
      });
  };
  const handleAudioDelete = async (id) => {
    axios
    .post(baseUrl + '/yoruba/delete_audio/' + id)
    .then((res) => {
      toastr.success('Audio deleted successfully', 'Success');
      // // Update the audioList state
      // // prevAudioList is the current state of audioList.
      // setAudioList((prevAudioList) => {
      //   const newAudioList = [...prevAudioList]; // Make a copy
      //   newAudioList[index] = null;
      //   return newAudioList;
      // });
      // Find the row in your data and update has_audio_file
      const updatedRows = rows.map(row => {
        if (row.yoruba_word_id === id) {
          return { ...row, has_audio_file: false };
        }
        return row;
      });

      setRows(updatedRows);
    })
    .catch((error) => {
      toastr.error('Error deleting audio', 'Error!');
      console.error('Error deleting audio:', error);
    });
  }
  const fetchData = async (pageIndex, pageSize)=> {
    axios
      .get(
        baseUrl + `/yoruba-word/all-dictionary?offset=${pageIndex}&limit=${pageSize}&search=${searchTerm}&type=${searchType}`
      )
      .then((res) => {
        if (res && res.data.status === 200) {
          // let updatedList = res.data.data.list.map((item) => {
          //   if (!item.has_audio_file) {
          //     item.audio_path = '';
          //   }
          //   return item;
          // });
          setRows(res.data.data.list);
          // setRows(res.data.data.list);
          // let audioUrls = res.data.data.list?.map((item) => {
          //   let audioUrl=''
          //   if(item?.audio_url?.type &&item?.audio_url?.data.length>0){
          //   const audioBlob = new Blob([new Int8Array(item?.audio_url?.data)], {
          //     type: 'audio/mp3',
          //   });
          //   audioUrl = URL.createObjectURL(audioBlob);
          // }
          // return audioUrl;
          // });
          // setAudioList(audioUrls);
          setTotalCount(parseInt(res.data.data?.totalCount));
          setShowLoader(false);
          // console.log("searchTerm ",searchTerm);
        }
      })
      .catch((err) => {
        console.log('error', err);
        setShowLoader(false);
      });
  };

  // useEffect(() => {
  //   fetchData(offset, limit);
  // }, [limit, offset]);
  const ITEMS_PER_PAGE = 10; // Number of items to display per page

  useEffect(() => {
    fetchData(currentPage, ITEMS_PER_PAGE);
  }, [searchTerm,currentPage,searchType]);

  const handleChange = (event, value) => {
    setShowLoader(true);
    setCurrentPage(value);
  };
  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
  const handleSearchChange = (event) => {
    setSearchType(event.target.value);
  };
  return (
    <div className={classes.root}>
      <Button
        style={{
          backgroundColor: '#257b59',
          color: 'white',
          padding: 10,
          alignSelf: 'flex-end',
          marginBottom: 20,
        }}
        onClick={() => {
          history.push('/add-yoruba-word');
        }}
      >
        Add Yoruba Word
      </Button>
      <Paper className={classes.paper}>
        {showLoader ? (
          <LoadIndicator />
        ) : (
          <>
           <InputLabel id="demo-simple-select-label">Search Type</InputLabel>
           <Select style={{paddingBottom:'10px'}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchType}
              label="Type"
              onChange={handleSearchChange}
              >
              <MenuItem value={'starts'}>Starts with</MenuItem>
              <MenuItem value={'contains'}>Contains</MenuItem>
              <MenuItem value={'match'}>Exact Matches with</MenuItem>
            </Select>
            <SearchBar value={searchTerm} onSearch={handleSearch} />
            {/* <CustomSearchBar
              rows={rows}
              setRows={setRows}
              keysToSearch={['yoruba_word_name', 'phonemic_transcription']}
            /> */}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby='tableWord'
                size={dense ? 'small' : 'medium'}
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                {rows.map((row, index) =>  {
                      return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                          <TableCell align='left'>
                            {row.yoruba_word_name}
                          </TableCell>
                          <TableCell align='left'>
                            {row.phonemic_transcription}
                          </TableCell>
                          <TableCell align='left'>
                            {row.audio_path ? row.audio_path.split('/').pop() : 'NA'}
                          </TableCell>
                          <TableCell align='left' width={'20%'}>
                          {row.has_audio_file && row.audio_path ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <audio controls>
                                <source src={row.audio_path} type='audio/mp3' />
                                Your browser does not support the audio element.
                              </audio>
                              <IconButton
                                variant='contained'
                                color='warning'
                                onDoubleClick={() => {
                                  handleAudioDelete(row.yoruba_word_id);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                          ) : (
                            'NA'
                          )}
                          </TableCell>
                          <TableCell width={'20%'} align='center'>
                            <IconButton
                              variant='contained'
                              color='#ddd'
                              onClick={() => {
                                history.push({
                                  pathname: '/add-yoruba-word',
                                  state: {
                                    isViewOnly: true,
                                    rowData: {
                                      yoruba_word_id: row.yoruba_word_id,
                                      yoruba_word_name: row.yoruba_word_name,
                                    },
                                  },
                                });
                              }}
                            >
                              <Visibility />
                            </IconButton>
                            <IconButton
                              variant='contained'
                              color='#ddd'
                              onClick={() => {
                                history.push({
                                  pathname: '/add-yoruba-word',
                                  state: {
                                    isViewOnly: false,
                                    rowData: {
                                      yoruba_word_id: row.yoruba_word_id,
                                      yoruba_word_name: row.yoruba_word_name,
                                    },
                                  },
                                });
                              }}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              variant='contained'
                              color='#ddd'
                              onClick={() => {
                                setselectedYorubaWord(row);
                                setOpenDeleteDailog(true);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component='div'
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
             <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChange}
              color="primary"
              variant="outlined" shape="rounded" 
            />
      </Stack>
      </div>
          </>
        )}
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label='Dense padding'
      />
      {openDeleteDailog && (
        <DeleteDailog
          data={selectedYorubaWord}
          visible={openDeleteDailog}
          handleClose={handleCloseDeleteDailog}
          Transition={Transition}
          dialogTitle={() => (
            <div className='mt-3'>
              Do you want to delete this WordType :{' '}
              <strong>{selectedYorubaWord?.yoruba_word_name || 'NA'}</strong>
            </div>
          )}
          handleDeleteClicked={(data) => handleDelete(data.yoruba_word_id)}
        />
      )}
    </div>
  );
}
