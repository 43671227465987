import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Row, Col, Alert, Container, CardBody, Card } from 'reactstrap';

import { withRouter, Link, useHistory } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import logo from '../../assets/images/sylo_logo.png';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axios from 'axios';
import encrypt, { baseUrl } from '../../helpers/EncryptionHelper';

const ForgetPasswordPage = (props) => {
  const history = useHistory();

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  function handleValidSubmit(event, values) {
    console.log('====================================');
    console.log(values);
    console.log('====================================');
    axios
      .put(baseUrl + '/users/admin-forgot-password', {
        email: encrypt(values.email),
      })
      .then((res) => {
        toastr.success('Email Sent Successfully', 'Success');
        history.push('/login');
      })
      .catch((error) => {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
        toastr.error('Unable to send email', 'Error!');
      });
  }

  return (
    <React.Fragment>
      <div className='account-pages my-5  pt-sm-5'>
        <Container>
          <div className='row justify-content-center'>
            <div className='col-md-8 col-lg-6 col-xl-5'>
              <div>
                <Link to='/' className='mb-5 d-block auth-logo'>
                  <img
                    src={logo}
                    alt=''
                    height='48'
                    className='logo logo-dark'
                  />
                </Link>
                <Card>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Reset Password</h5>
                      <p className='text-muted'>
                        Reset Password for the Dictionary Admin.
                      </p>
                    </div>
                    <div className='p-2 mt-4'>
                      <div
                        className='alert alert-success text-center mb-4'
                        role='alert'
                      >
                        Enter your Email and instructions will be sent to you!
                      </div>
                      {props.forgetError && props.forgetError ? (
                        <Alert
                          color='danger'
                          className='text-center mb-4'
                          style={{ marginTop: '13px' }}
                        >
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert
                          color='success'
                          className='text-center mb-4'
                          style={{ marginTop: '13px' }}
                        >
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className='form-horizontal'
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        <div className='mb-3'>
                          <AvField
                            name='email'
                            label='Email'
                            className='form-control'
                            placeholder='Enter email'
                            type='email'
                            required
                          />
                        </div>
                        <Row className='row mb-0'>
                          <Col className='col-12 text-end'>
                            <button
                              className='btn btn-primary w-md waves-effect waves-light'
                              type='submit'
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                        <div className='mt-4 text-center'>
                          <p className='mb-0'>
                            Remember It ?{' '}
                            <Link
                              to='/login'
                              className='fw-medium text-primary'
                            >
                              {' '}
                              Signin{' '}
                            </Link>
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center'>
                  <p>
                    © {new Date().getFullYear()} Dictionary. Crafted with{' '}
                    <i className='mdi mdi-heart text-danger' /> in India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
};

export default withRouter(ForgetPasswordPage);
