import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import StickyKeyboard from '../Common/StickyKeyboard';

const Header = (props) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false); // State to manage sidebar visibility

  function tToggle() {
    setIsSidebarOpen(!isSidebarOpen);
    props.toggleMenuCallback();
  }
  return (
    <React.Fragment>
      <header id='page-topbar' style={{ zIndex: 100000 }}>
        <div className='navbar-header'>
          <div className='d-flex'>
            {isMobile && (
              <Link
                to='/'
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                {/* <img
                  src={logoLight}
                  alt=''
                  style={{ height: 40, alignSelf: 'center' }}
                /> */}
              </Link>
            )}

            <button
              type='button'
              onClick={() => {
                tToggle();
              }}
              className='btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn'
              id='vertical-menu-btn'
            >
              <i
                className={`fa fa-fw ${isSidebarOpen ? 'fa-times' : 'fa-bars'}`}
              />
            </button>
          </div>

          <div className='d-flex'>
            <StickyKeyboard />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
};

export default Header;
