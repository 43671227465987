import { Dialog } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Input, Label } from 'reactstrap';

import { baseUrl } from '../../helpers/EncryptionHelper';
import useEnterKeyListener from '../../helpers/useEnterKeyListener';
import { Box, FormControl, InputLabel, MenuItem, Select, TextareaAutosize } from '@mui/material';
const GameMasterDialog = ({
  data,
  visible,
  handleClose,
  Transition,
  editSubscriptionPlan,
}) => {
  const [name, setName] = useState('');
  
  const [userDesc, setUserDesc] = useState('');
  const [adminDesc, setAdminDesc] = useState('');

  // const [type, setType] = React.useState('');

  function handleValidation() {
    let errorText;
    if (!name) {
      errorText = 'Please enter WordType';
    }
    if (errorText) {
      toastr.error(errorText, 'Error!');
    } else {
      handleAddEditWordType(editSubscriptionPlan);
    }
  }

  function handleAddEditWordType(isEdit) {
    let endpoint = isEdit
      ? `/game-master/edit/${data?.game_master_id }`
      : '/game-master/add-game-master';
    let axiosMethod = isEdit ? axios.put : axios.post;
    axiosMethod(baseUrl + endpoint, {
      game_name: name,
      user_description: userDesc,
      admin_description: adminDesc,

    })
      .then((res) => {
        toastr.success(
          `Word of the day ${isEdit ? 'Edited' : 'Added'} Successfully`,
          'Success'
        );
        window.location.reload(false);
      })
      .catch((error) => {
        toastr.error(
          `Word of the day ${isEdit ? 'Edit' : 'Add'} Failed`,
          'Error!'
        );
      });
  }
  

  useEnterKeyListener({
    querySelectorToExecuteClick: '#submitDialectType',
  });

  useEffect(() => {
    if (data && editSubscriptionPlan) {
      // If it's an edit and data is available, prefill the form
      setName(data?.game_name || '');
      setAdminDesc(data?.admin_description || '');
      setUserDesc(data?.user_description || '');

    } else {
      // If it's not an edit or no data is available, reset the form
      setName('');
      setAdminDesc('');
      setUserDesc('');

    }
  }, [data, editSubscriptionPlan]);
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{marginTop:'5%'}}
    >
<div style={{ width: 400, padding: 40, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <CloseIcon
    style={{
      position: 'absolute',
      top: 20,
      right: 20,
      cursor: 'pointer',
    }}
    onClick={handleClose}
  />
  <div style={{ marginBottom: '10px', width: '100%' }}>
    <Label className='form-label' htmlFor='addDialectTypeInput'>
      Quiz Name
    </Label>
    <Input
      type='text'
      className='form-control'
      id='addDialectTypeInput'
      value={name}
      disabled={data && !editSubscriptionPlan}
      onChange={(e) => setName(e.target.value)}
    />
  </div>
  <div style={{ marginBottom: '10px', width: '100%' }}>
    <Label className='form-label' htmlFor='addDialectTypeInput'>
      User Description
    </Label>
    <Input
      type='text'
      className='form-control'
      id='addUserInput'
      value={userDesc}
      disabled={data && !editSubscriptionPlan}
      onChange={(e) => setUserDesc(e.target.value)}
    />
  </div>
  <div style={{ marginBottom: '10px', width: '100%' }}>
    <Label className='form-label' htmlFor='addDialectTypeInput'>
      Admin Description
    </Label>
    <Input
      type='text'
      className='form-control'
      id='addAdminInput'
      value={adminDesc}
      disabled={data && !editSubscriptionPlan}
      onChange={(e) => setAdminDesc(e.target.value)}
    />
  </div>
  {/* <div style={{ width: '100%', marginBottom: '10px' }}>
    <Label className='form-label' htmlFor='demo-simple-select'>
      Type
    </Label>
    <Select
      labelId='demo-simple-select-label'
      id='demo-simple-select'
      value={type}
      onChange={(e) => setType(e.target.value)}
      style={{ width: '100%', height: '5.4vh' }} // Adjusted width
    >
      <MenuItem value={"yoruba-english"}> yoruba-english </MenuItem>
      <MenuItem value={"english-yoruba"}> english-yoruba </MenuItem>

    </Select>
  </div> */}
       
        {!(data && !editSubscriptionPlan) && (
          <div className='mt-4'>
            <Button
              id='submitDialectType'
              color='primary'
              variant='contained'
              className='w-md'
              onClick={() => {
                handleValidation();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default GameMasterDialog;
