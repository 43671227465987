import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const Logout = (props) => {
  useEffect(() => {
    props.logoutUser(props.history);
  });

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
};

export default withRouter(Logout);
